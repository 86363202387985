/**
 * @typedef EventHandler
 * @property {string} id layerID
 * @property {import("maplibre-gl").MapLayerEventType} type onしたeventのタイプ(clickとか)
 * @property {(e: object) => void} func event発生時に動くfunction
 */

/**
 * ベースとなるレイヤークラス
 */
export default class BaseLayer {
  /** @type{maplibregl.Map} */
  map;

  /** @type{string} */
  sourceName;

  /** @type{EventHandler[]} レイヤーのイベントに対して当てるハンドラ群 */
  eventHandlers = [];

  /** @type{(() => void)[]} レイヤー削除時に発動させたいfunction */
  removeHandlers = [];

  /**
   * 地図にレイヤーを載せる
   *
   * @param {maplibregl.Map} map
   */
  addTo(map) {
    this.map = map;

    // データソース追加は派生クラスで定義
    this.addSource(map);

    // レイヤー追加は派生クラスで定義
    return this.addLayer(map);
  }

  /**
   * レイヤーを地図から消す
   */
  remove() {
    // レイヤーのイベントを削除
    this.eventHandlers.forEach((handler) => {
      if (!handler.id) {
        this.map.off(handler.type, handler.func);
      } else {
        this.map.off(handler.type, handler.id, handler.func);
      }
    });

    // レイヤー削除は派生クラスで定義
    this.removeLayer();

    // sourceの削除
    this.map.removeSource(this.sourceName);

    // ユーザー定義の削除時処理を動かす
    this.removeHandlers.forEach((handler) => {
      handler();
    });
  }

  /**
   * レイヤー削除時のイベントを追加する
   * @param {() => void} handlerFunction
   */
  addRemoveEventHandler(handlerFunction) {
    this.removeHandlers.push(handlerFunction);
  }
}
