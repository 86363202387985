/**
 * レイヤー命名規則
 *
 * 現状, 呼び出し側は数字で管理されており, Maplibre内部のIDとぶつかる可能性を回避するために一定の規則で名前をつける
 * @param {String|Number} id 呼び出し側から指定されたID
 * @returns
 */
export function layerName(id) {
  return `user-layer-${id}`;
}

/**
 * データソース命名規則
 * 現状, 呼び出し側は数字で管理されており, Maplibre内部のIDとぶつかる可能性を回避するために一定の規則で名前をつける
 * @param {String|Number} id 呼び出し側から指定されたID
 * @returns
 */
export function sourceName(id) {
  return `user-source-${id}`;
}
