import MapboxDraw from "@mapbox/mapbox-gl-draw";

/* eslint-disable no-underscore-dangle */
/* eslint-disable no-multi-assign */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
const dragPan = {
  enable(ctx) {
    setTimeout(() => {
      // First check we've got a map and some context.
      if (
        !ctx.map ||
        !ctx.map.dragPan ||
        !ctx._ctx ||
        !ctx._ctx.store ||
        !ctx._ctx.store.getInitialConfigValue
      )
        return;
      // Now check initial state wasn't false (we leave it disabled if so)
      if (!ctx._ctx.store.getInitialConfigValue("dragPan")) return;
      ctx.map.dragPan.enable();
    }, 0);
  },
  disable(ctx) {
    setTimeout(() => {
      if (!ctx.map || !ctx.map.doubleClickZoom) return;
      // Always disable here, as it's necessary in some cases.
      ctx.map.dragPan.disable();
    }, 0);
  },
};

const DrawFreeHandPolygonMode = { ...MapboxDraw.modes.draw_polygon };

DrawFreeHandPolygonMode.onSetup = function() {
  const polygon = this.newFeature({
    type: MapboxDraw.constants.geojsonTypes.FEATURE,
    properties: {},
    geometry: {
      type: MapboxDraw.constants.geojsonTypes.POLYGON,
      coordinates: [[]]
    }
  });

  this.addFeature(polygon);
  this.clearSelectedFeatures();
  
  dragPan.disable(this);

  this.updateUIClasses({ mouse: MapboxDraw.constants.cursors.ADD });
  this.activateUIButton(MapboxDraw.constants.types.POLYGON);
  this.setActionableState({
    trash: true
  });

  return {
    polygon,
    currentVertexPosition: 0,
    dragMoving: false
  };
};

DrawFreeHandPolygonMode.onDrag = DrawFreeHandPolygonMode.onTouchMove = function (state, e){
  state.dragMoving = true;
  this.updateUIClasses({ mouse: MapboxDraw.constants.cursors.ADD });
  state.polygon.updateCoordinate(`0.${state.currentVertexPosition}`, e.lngLat.lng, e.lngLat.lat);
  state.currentVertexPosition++;
  state.polygon.updateCoordinate(`0.${state.currentVertexPosition}`, e.lngLat.lng, e.lngLat.lat);
}

DrawFreeHandPolygonMode.onMouseUp = function (state, e){
  dragPan.enable(this);
  return this.changeMode(MapboxDraw.constants.modes.SIMPLE_SELECT, { featuresId: state.polygon.id });
}

DrawFreeHandPolygonMode.onTouchEnd = function(state, e) {
  this.onMouseUp(state, e)
}
  
export default DrawFreeHandPolygonMode