/**
 * 時系列生成GeoJsonレイヤー
 * @module app/observation/map/TimeSeriesGeoJsonLayer
 */

import { GeoJsonLayer } from "idismap";

define([
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/promise/all',
    'dojo/topic',
    'idis/service/Requester',
    'idis/consts/QUERY',
    'idis/control/Locator',
    'app/observation/LatestData',
    'app/map/layer/StyleLoader'
], function (declare, lang, Deferred, all, topic, Requester, QUERY, Locator, LatestData, StyleLoader) {

    return declare(null, {
        /** @type {import("idismap").GeoJsonLayer} */
        layer: null,

        /** @type {number} layerId */
        layerId: null,

        /** @type {object} styleJs */
        styleJs: {},

        /** @type {string} 現在の最新の時刻が書いてあるjsonへのURL */
        statusUrl: null,

        /** @type {string} レイヤーの地物種類(Point/Polygon/etc.) */
        geometryType: null,

        /** @type {RequestMemory} 観測局マスターストア */
        store: null,

        /** @type {object} 今表示している時点でのURL状態, URL更新時の比較用 */
        _lastQuery: {},

        /** @type {string} 観測データの最新時刻を保管 */
        _latestDataTimestamp: null,  // 観測データの最新時刻を保管

        _handle: null, // Locatorを監視するハンドラ
        _observatories: {}, // 観測局一覧

        /**
         *
         * @param {object} layerInfo
         * @param {object} styleJs style.jsの内容
         */
        constructor: function (layerInfo, styleJs) {
            this.layerId = layerInfo.id;
            this.statusUrl = layerInfo.layerUrl;
            this.layerOption = this.generateLayerOption(layerInfo, styleJs);
        },

        /**
         * layerInfoの情報からレイヤーオプションを作る
         *
         * minZoom/maxZoomが入っていたらそれを入れる.
         *
         * 将来 Idismap.GeoJsonLayer.GeoJsonLayerParameterのプロパティが増えて,
         * そのプロパティがlayerInfoの情報を使うときはここに足す.
         * @param {object} layerInfo
         * @param {object} styleJs
         * @returns レイヤーオプションのオブジェクト
         */
        generateLayerOption: function(layerInfo, styleJs) {
            var result = {
                styleJs: styleJs,
            };

            // layerInfoに入っているオプションを適用
            ['minZoom', 'maxZoom'].forEach(function(prop) {
                if (layerInfo[prop]) {
                    result[prop] = layerInfo[prop];
                }
            });

            return result;
        },

        /**
         * レイヤー生成
         *
         * @returns {promise} レイヤーが生成されたらresolveされるもの
         */
        createLayer: function () {
            var latestData = new LatestData({url: this.statusUrl + '/latest.json'});
            var promise = new Deferred();

            latestData.load().then(
                lang.hitch(this, function () {
                    // クエリーに時間指定があったらその時間のものを表示
                    this._lastQuery = Locator.getQuery();
                    if (this._lastQuery.datetime) {
                        this._latestDataTimestamp = this._lastQuery.datetime;

                        // クエリーに時間指定がなかったら最新を表示
                    } else {
                        var timestamp = latestData.getLatestDateTimestamp();
                        // YYYY-mm-DD HH:MM:ss -> YYYY-mm-DD-HH-MM
                        this._latestDataTimestamp =
                            timestamp.replace(/(\d{4}-\d{2}-\d{2}) (\d{2}):(\d{2}):\d{2}/, '$1-$2-$3');
                    }

                    // 初期表示
                    this._refresh(this._latestDataTimestamp).then(
                        lang.hitch(this, function(geojson) {
                            this.layer = new GeoJsonLayer(this.layerId,
                                lang.mixin(this.layerOption, {
                                    geojson: geojson,
                                })
                            );

                            // URLの変更を監視
                            var handle = Locator.on('change', lang.hitch(this, this.onLocationChanged));

                            // このレイヤーが削除された時に動かすfunctionを登録
                            this.layer.addRemoveEventHandler(function () {
                                handle.remove();
                                // 時刻表示を消してもらう
                                topic.publish('app/map/TimestampsPanel::disappear');
                            });

                            promise.resolve();
                        })
                        , function(error) {
                            promise.reject(error);
                        }
                    );
                }),
                function (error) {
                    promise.reject(error);
                }
            );

            return promise;
        },

        // URL変更時の処理
        // 時刻が変わったら観測情報を取得してレイヤーを更新
        onLocationChanged: function () {
            // 現在のクエリー情報を取得
            var query = Locator.getQuery();

            // 緯度経度、レイヤー、ズームレベルが変更された場合はデータの更新はないので何もしない
            // 最新日時が選択されている状態で「最新」ボタンをクリックすると、日時は変わっていないがデータが
            // 更新されている可能性があるので、日付の一致は確認しない
            if (query[QUERY.LATLNG] !== this._lastQuery[QUERY.LATLNG]) {
                this._lastQuery = query;
                return;
            }
            if (query[QUERY.LAYER_LIST] !== this._lastQuery[QUERY.LAYER_LIST]) {
                this._lastQuery = query;
                return;
            }
            if (query[QUERY.ZOOM] !== this._lastQuery[QUERY.ZOOM]) {
                this._lastQuery = query;
                return;
            }

            // 日時や時間モードが指定されてなかった時のデフォルト値
            var datetime = query.datetime ? query.datetime : this._latestDataTimestamp;
            // レイヤーを更新
            this._refresh(datetime).then(
                lang.hitch(this, function(geojson) {
                    this.layer.updateData(geojson);
                }),
                function(error) {
                    throw new Error(error);
                }
            );
            // 次の更新に備えてクエリー状態を保存
            this._lastQuery = query;
        },

        // レイヤー内容を指定された時間の観測情報で更新
        // ファイルは [layerUrl]/list/yyyy-mm-dd-HH-MM.geojson を見にいく
        _refresh: function (timestamp) {
            var url = this.statusUrl + '/list/' + timestamp + '.geojson';

            return Requester.get(url).then(
                lang.hitch(this, function (geojson) {
                    this._latestDataTimestamp = timestamp;

                    // トップページに表示レイヤーの時刻表示用のpub
                    topic.publish('app/map/TimestampsPanel::show', {
                        timestamp: timestamp,
                        layerName: this.statusUrl.split('/').slice(-1)[0]
                    });

                    return geojson;

                }),
                function(error) {
                    throw new Error(error);
                }
            );
        }
    });
});
