/**
 * 地図内で使用するフォントを背景地図のstyleごとに決めるもの
 *
 * 地図内で使うフォントは, cssのようにローカルに存在するフォントは使用できない.
 * 使用できるのは背景地図のstyleでglyphに書かれたURLが持っているweb-fontだけ.
 * 背景地図を切り替えると使えるフォントの種類も異なるので,
 * - 最初に表示するとき
 * - 背景地図を切り替えるとき
 * に今の背景地図が何を使っていて,どのフォントを使えるかを判断して切り替える必要がある.
 */

const domainAndFont = {
  "basemaps-api.arcgis.com": "Arial Unicode MS Regular",
  "maps.gsi.go.jp": "NotoSansCJKjp-Regular",
  "tile.openstreetmap.jp": "migu1c-regular",
};

const gsiGlyphUrl =
  "https://maps.gsi.go.jp/xyz/noto-jp/{fontstack}/{range}.pbf";

/**
 * GlyphのURLから使えるフォントを返す
 *
 * @param {string} urlString glyphを表すURL
 * @returns {string} font-name
 */
export function getFontNameFromUrl(urlString) {
  const url = new URL(urlString);
  if (!domainAndFont[url.hostname]) {
    throw new Error(`${url.hostname}に対するwebfontが見つからない.`);
  }

  return domainAndFont[url.hostname];
}

/**
 * Mapオブジェクトから使えるフォントを返す
 *
 * @param {maplibregl.Map} map
 * @returns {string} font-name
 */
export function getFontNameFromMap(map) {
  // 画像地図など,背景地図にglyphが登録されていない場合がある.
  // その時は地理院が提供するglyphを使わせてもらう.
  if (!map.getStyle().glyphs) {
    map.setGlyphs(gsiGlyphUrl);
  }

  const url = new URL(map.getStyle().glyphs);
  if (!domainAndFont[url.hostname]) {
    throw new Error(`${url.hostname}に対するwebfontが見つからない.`);
  }

  return domainAndFont[url.hostname];
}
