/**
 * 背景地図など, 他のレイヤーの下に敷くもの
 */
export default {
  gsidem: {
    type: "raster-dem",
    tiles: [
      "gsidem://https://cyberjapandata.gsi.go.jp/xyz/dem_png/{z}/{x}/{y}.png",
    ],
    tileSize: 256,
    maxzoom: 14,
  },
};
