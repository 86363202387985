/**
 * 複数のGeoJSONを1つのレイヤーに表示させるためのレイヤー
 *
 * 使う場合は, layerInfo.filesに表示させたいGeoJSONの
 * URLリストを入れる.
 *
 */

import { GeoJsonLayer } from "idismap";

define([
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/promise/all',
    'idis/service/Requester',
], function(
    array, declare, lang, Deferred, all,
    Requester,
) {

    return declare(null, {

        files: ['red.geojson', 'yellow.geojson'], // GeoJSONのURLをいれるarray
        parent: null,

        /** @type {import("idismap").GeoJsonLayer} */
        layer: null,

        constructor: function(layerInfo, styleJs) {
            this.parent = layerInfo.layerUrl;
            this.files = layerInfo.files;
            this.layerOption = this.generateLayerOption(layerInfo, styleJs);
        },

        /**
         * layerInfoの情報からレイヤーオプションを作る
         *
         * minZoom/maxZoomが入っていたらそれを入れる.
         *
         * 将来 Idismap.GeoJsonLayer.GeoJsonLayerParameterのプロパティが増えて,
         * そのプロパティがlayerInfoの情報を使うときはここに足す.
         * @param {object} layerInfo
         * @param {object} styleJs
         * @returns レイヤーオプションのオブジェクト
         */
        generateLayerOption: function(layerInfo, styleJs) {
            var result = {
                styleJs: styleJs,
            };

            // layerInfoに入っているオプションを適用
            ['minZoom', 'maxZoom'].forEach(function(prop) {
                if (layerInfo[prop]) {
                    result[prop] = layerInfo[prop];
                }
            });

            return result;
        },

        /**
         * レイヤー生成
         *
         * @returns {promise} レイヤーが生成されたらresolveされるもの
         */
        createLayer: function() {
            this._generateGeoJSON().then(
                lang.hitch(this, function(geojson) {
                    this.layer = new GeoJsonLayer(this.layerId,
                        lang.mixin(this.layerOption, {
                            geojson: geojson,
                        })
                    );
                }),
                function(error) {
                    throw new Error(error);
                }
            );
        },

        /**
         * GeoJSONを読み込んで作る
         */
        _generateGeoJSON: function() {
            var promise = new Deferred();

            all(
                array.map(this.files, lang.hitch(this, this._request))
            ).then(lang.hitch(this, function(results) { // resultsにはarrayが入る

                var geojsonList = array.filter(results, function(item) {
                    return (item); // null や undefinedではないものでfilter
                });

                // 中身が空(全てのURLに対するリクエストがエラー)なら
                // 何もしない
                if (geojsonList.length === 0) {
                    promise.reject();
                }

                var geojson = {
                    type: 'FeatureCollection',
                    features: []
                };

                // それぞれのgeojsonにあるfeatureを追加
                array.forEach(geojsonList, function(item) {
                    var a = geojson.features.concat(item.features);
                    geojson.features = a;
                });

                promise.resolve(geojson);
            }));

            return promise;
        },

        /**
         * GeoJSONのfileに対してリクエストする
         * エラーになった場合はdataにnullを入れてresolveする(rejectしない)
         */
        _request: function(file) {
            var deferred = new Deferred();

            var url = [this.parent, file].join('/');
            Requester.get(url).then(
                function(data) {
                    return deferred.resolve(data);
                },
                function() {
                    // エラー時はnullを入れてresolve
                    return deferred.resolve(null);
                }
            );

            return deferred;
        }
    });
});