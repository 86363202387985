// 地理院標高タイル形式からMapbox Terrain RGBに変換
const gsiDem2TerrainRGB = (r, g, b) => {
  let height = r * 655.36 + g * 2.56 + b * 0.01;
  if (r === 128 && g === 0 && b === 0) {
    height = 0;
  } else if (r >= 128) {
    height -= 167772.16;
  }
  height += 10000;
  height *= 10;
  const tB = (height / 256 - Math.floor(height / 256)) * 256;
  const tG =
    (Math.floor(height / 256) / 256 -
      Math.floor(Math.floor(height / 256) / 256)) *
    256;
  const tR =
    (Math.floor(Math.floor(height / 256) / 256) / 256 -
      Math.floor(Math.floor(Math.floor(height / 256) / 256) / 256)) *
    256;
  return [tR, tG, tB];
};

export default function gsiDem(params, callback) {
  const image = new Image();
  image.crossOrigin = "";
  image.onload = () => {
    const canvas = document.createElement("canvas");
    canvas.width = image.width;
    canvas.height = image.height;

    const context = canvas.getContext("2d");
    context.drawImage(image, 0, 0);
    const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
    for (let i = 0; i < imageData.data.length / 4; i += 1) {
      const [r, g, b] = gsiDem2TerrainRGB(
        imageData.data[i * 4],
        imageData.data[i * 4 + 1],
        imageData.data[i * 4 + 2],
      );
      imageData.data[i * 4] = r;
      imageData.data[i * 4 + 1] = g;
      imageData.data[i * 4 + 2] = b;
    }
    context.putImageData(imageData, 0, 0);
    canvas.toBlob(
      (blob) =>
        blob.arrayBuffer().then((arr) => callback(null, arr, null, null)), // ここで返すデータは、画像のArrayBuffer()でなければならない
    );
  };
  image.src = params.url.replace("gsidem://", "");
  return {
    cancel: () => {
      /*  成功時のidiom ref. https://maplibre.org/maplibre-gl-js-docs/api/properties/#addprotocol */
    },
  };
}
