import { layerName, sourceName as sourceNameRule } from "../common/name";
import VectorLayer from "./vector_layer";

/**
 * @typedef VectorTileLayerParameter
 * @property {string} url 表示するレイヤーのURL
 * @property {string} layerName ベクトルタイルの中にあるレイヤー名
 * @property {string} scheme tms or xyz.
 * @property {number} minZoom レイヤーを表示する最小のズームレベル. これ以上地図を引くと表示が消える
 * @property {number} maxZoom レイヤーを表示する最大のズームレベル. これ以上地図を寄ると表示が消える
 * @property {number} overlayZoom タイルが配置されている最大のズームレベル. これ以上地図を寄ると指定ズームレベルのタイルを引き伸ばして表示する
 * @property {VectorTileStyleJs} styleJs 表示をカスタマイズするためのstyle. "statusKey","match","filter"は使用できない.
 */

/**
 * @typedef VectorTileStyleJs
 * @property {string[]} iconUrls 使用するアイコンURL
 * @property {FillPattern[]} fillPatterns 塗りつぶしに適用するsvg定義
 * @property {string} layer 表示するベクトルタイル内のレイヤー名, pbfには複数のレイヤーが格納可能で,最低1つ存在する.それを指定しないと何も表示されない.
 * @property {object} style アイコンの定義などを指定するスタイル, Maplibreのスタイルフォーマットに準拠 ref. https://maplibre.org/maplibre-style-spec/layers/
 * @property {object[]} styles styleのArray
 * @property {(properties: object) => string} popup 地物をクリックしたときに表示するポップアップの中身を作るfunction
 */

/**
 * ベクトルタイル(pbf)を地物表現に使うレイヤー操作を行うクラス
 *
 * - レイヤー生成
 * - 地図に載せる, 外す
 * - その他操作
 *
 * を行う.
 * レイヤー1つにつき, maplibreのsource1つ, maplibreのlayer1つが存在する.
 *
 */
export default class VectorTileLayer extends VectorLayer {
  /** @type{VectorTileLayerParameter} */
  parameter = {};

  /** @type{string|number} 利用元から渡されるレイヤーID */
  layerId;

  /**
   * constructor
   *
   * @param {String|Number} layerId レイヤーID
   * @param {VectorTileLayerParameter} parameter
   */
  constructor(layerId, parameter) {
    super(layerId, parameter);

    this.layerId = layerId;
    this.parameter = parameter;
  }

  /**
   * 地図にレイヤーを載せる
   *
   * @param {maplibregl.Map} map
   */
  addTo(map) {
    this.map = map;
    this.addSource(map);

    return this.addLayer(map);
  }

  /**
   * 地図にデータ(source)を登録
   *
   * @param {maplibregl.Map} map 登録する先のmap
   */
  addSource(map) {
    // geojsonをsourceに追加
    this.sourceName = sourceNameRule(this.layerId);
    const source = map.getSource(this.sourceName);
    if (!source) {
      map.addSource(this.sourceName, this.generateSourceParameter());
    } else {
      source.setData(this.sourceData);
    }
  }

  /**
   * レイヤーを表すファイル種別を判定してデフォルトパラメーターを組み合わせる
   *
   * pbf/pmtilesなどをlayerUrlから判定
   *
   * @returns {VectorTileLayerParameter} 組み合わせた結果のパラメーター
   */
  generateSourceParameter() {
    const result = {
      type: "vector",
      maxzoom:
        this.parameter.overlayZoom ||
        this.parameter.maxZoom ||
        this.map.getMaxZoom(),
    };
    const url = this.parameter.url.toLowerCase();
    if (url.endsWith(".pbf")) {
      Object.assign(result, {
        scheme: this.parameter.scheme || "xyz",
        tiles: [this.parameter.url],
      });
    } else if (url.endsWith(".pmtiles")) {
      Object.assign(result, {
        url: `pmtiles://${this.parameter.url}`,
      });
    } else {
      throw new Error("指定されたlayerUrlから種別(pbf/pmtiles)を判定できない.");
    }

    return result;
  }

  /**
   * レイヤー追加で使うレイヤー定義を作る
   *
   * geojsonが持っているfeatureの種類によって, 複数のレイヤーを追加することがある. それぞれについて定義を作る.
   * 具体的には, styleに指定されたkey(line/symbol/polygonなど)それぞれについて定義を一つ生成する.
   */
  makeLayerDefinitions() {
    // styleJs.styleについて
    if (this.parameter.styleJs.style) {
      Object.keys(this.parameter.styleJs.style).forEach((type) => {
        this.layerDefinitions.push({
          id: `${layerName(this.layerId)}-${type}`,
          source: this.sourceName,
          "source-layer": this.parameter.styleJs.layer,
          minzoom: this.parameter.minZoom || this.map.getMinZoom(),
          maxzoom: this.parameter.maxZoom || this.map.getMaxZoom(),
          type,
          ...this.parameter.styleJs.style[type], // style.jsの中にある"style"プロパティの中身をそのまま入れる
        });
      });
    }

    // style.styles[]について
    if (
      this.parameter.styleJs.styles &&
      Array.isArray(this.parameter.styleJs.styles)
    ) {
      this.parameter.styleJs.styles.forEach((style, i) => {
        Object.keys(style).forEach((type) => {
          this.layerDefinitions.push({
            id: `${layerName(this.layerId)}-styleArray${i}-${type}`,
            source: this.sourceName,
            "source-layer": this.parameter.styleJs.layer,
            minzoom: this.parameter.minZoom || this.map.getMinZoom(),
            maxzoom: this.parameter.maxZoom || this.map.getMaxZoom(),
            type,
            ...style[type], // style.jsの中にある"style"プロパティの中身をそのまま入れる
          });
        });
      });
    }
  }
}
