/**
 * geojsonのfeature.propertiesに状態をmergeする
 *
 * @param {object} feature geojson内の1つのfeature
 * @param {object} status featureの状態を表すobject, この内容を全てfeature.propertiesに入れる
 * @returns mergeされたfeature
 */
function merge(feature, status) {
  const mergedFeature = feature;
  Object.keys(status).forEach((key) => {
    if (
      mergedFeature.properties[key] &&
      mergedFeature.properties[key] !== status[key]
    ) {
      mergedFeature.properties[key] += `,${status[key]}`;
    } else {
      mergedFeature.properties[key] = status[key];
    }
  });

  return mergedFeature;
}

/**
 * filterに適合しないfeatureを取り除き, statusをmergeする
 *
 * マージする => statusの内容をGeoJSONに書き写す
 *
 * filterAndMergeは"statusと一致しないものは取り除く".
 *
 * mergeStatusは"statusと一致しないものも取り除かない(表示対象とする)".
 *
 * @param {object[]} features 表示するGeoJSONの中にあるFeatures
 * @param {object[]} statuses 気象注意報や震度など,地物の状態を表す情報が入ったArray
 * @param {(feature: object, status: object|undefined) => boolean} filterFunc 残したいfeatureをfilterするfunction. 結果がtrueのものを残す
 * @returns 表示対象となるfeature, statusArrayが指定されていたら, マッチするfeatureに状態をmergeした状態にする
 */
export function filterAndMerge(features, statusArray, filterFunc) {
  const filteredFeatures = [];
  features.forEach((feature) => {
    if (statusArray) {
      statusArray.forEach((status) => {
        if (filterFunc(feature, status)) {
          filteredFeatures.push(merge(feature, status));
        }
      });
    } else if (filterFunc(feature)) {
      filteredFeatures.push(feature);
    }
  });

  return filteredFeatures;
}

/**
 * GeoJSONとstatusをマージする
 *
 * マージする => statusの内容をGeoJSONに書き写す
 *
 * filterAndMergeは"statusと一致しないものは取り除く".
 *
 * mergeStatusは"statusと一致しないものも取り除かない(表示対象とする)".
 *
 * - 例: 行政界のGeoJSONと気象注意報/警報のalert.jsonをマージする
 * - 例: 震度観測点のGeoJSONと震度一覧の情報をマージする
 * @param {object[]} features 表示するGeoJSONの中にあるFeatures
 * @param {object[]} statuses 気象注意報や震度など,地物の状態を表す情報が入ったArray
 * @param {(feature: object, status: object) => boolean} matcherFunc statusesの中の要素がfeatures内の要素と一致するかを判定するfunction
 * @returns マージされたFeatures
 */
export function mergeStatus(features, statuses, matcherFunc) {
  const mergedFeatures = [];

  statuses.forEach((status) => {
    features.forEach((feature) => {
      // matchしたらstatusの内容をfeatureに書き写す
      // matchするfeatureは複数ある可能性がるので, ループは途中でやめない
      if (matcherFunc(feature, status)) {
        mergedFeatures.push(merge(feature, status));
      }
    });
  });

  return mergedFeatures;
}

/**
 * URLからアイコンをloadして, mapにaddする
 *
 * @param {maplibregl.Map} map
 * @param {URL} url
 */
export function loadIcon(map, url) {
  return new Promise((resolve, reject) => {
    map.loadImage(url, (error, image) => {
      if (error) {
        reject(Error(`レイヤーに出すアイコンのロード失敗: ${error.message}`));
      }

      map.addImage(url, image);
      resolve();
    });
  });
}

/**
 * GeoJsonに入っている最初のfeatureからタイプを判定
 *
 * GeoJsonの仕様としては, "point"と"polygon"の混在も可能だが, ここでは考えずに"全てのFeature Typeが同じ"とみなす
 * @param {Object} geojson
 * @returns {string[]} "Maplibre Style Spec" の "layers" - "type" を表す文字列のarray
 */
export function determineType(geojson) {
  switch (geojson.features[0].geometry.type.toLowerCase()) {
    case "point":
    case "multipoint":
      return ["symbol"];
    case "linestring":
    case "multilinestring":
      return ["line"];
    case "polygon":
    case "multipolygon":
      return ["fill", "line"];
    default:
      return [];
  }
}
