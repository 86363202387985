/**
 * レイヤーの透過度を表すプロパティをまとめたもの.
 * ref. https://maplibre.org/maplibre-style-spec/layers/
 *
 * layer-type: [typeに紐づくプロパティのarray]
 */
export default {
  fill: ["fill-opacity"],
  line: ["line-opacity"],
  symbol: ["icon-opacity", "text-opacity"],
  raster: ["raster-opacity"],
  circle: ["circle-opacity", "circle-stroke-opacity"],
  "fill-extrusion": ["fill-extrusion-opacity"],
  heatmap: ["heatmap-opacity"],
};
