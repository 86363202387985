import { greatCircle, point } from "@turf/turf";
import * as usng from "../common/usng";

// UTMポイントについての事前情報
// <読み方>
//  「52S CB 0000 6000」：「52S」をzone。「CB」をgrid (aka 格子ID)。「0000」(=一つ目の4桁数字)をeasting。「6000」(=二つ目の4桁数字)をnorthing。)
//   注意: 国際基準では、今回のこの格子IDを持つ記法(ZZBGGEEEEENNNNN ex.52SCB00006000)で書かれるものは、USNGやMRGSと呼ばれる。
//        日本国内で使われる「 UTM グリッド」の国際呼称はMGRSと認識しておきましょう。
// <日本領域においての法則>
//   zoneの数字(ex.「52」aka UTMゾーン)：東に向かって数字が上がる。
//   zoneの文字(ex. 「S」aka 緯度バンド)：北に向かって文字が昇る。
//   gridの1文字目(ex. 「C」)：東に向かって文字が昇る。
//   gridの2文字目(ex. 「B」)：北に向かって文字が昇る。
//   easting(ex. 「0000」)：gridの最西端が0000。最東端が9999。
//   northing(ex. 「6000」)：gridの最南端が0000。最北端が9999。
// USNGの利用について
//   本来米国での標準的な位置参照システムとして開発されたものだが、usng.jsの中身を見ると離心率と長半径が日本範囲で使いたいWGS 84と同値のため今回はそのまま使用している。
export default function addUTMGrid(map) {
  // 地理院で使っているzoneのgrid範囲の配列 (除外文字列：I,O,W,X)
  const zonesGridRange = {
    "51U": { eastRange: "TUVWX", northRange: "PQRSTUVABC" },
    "52U": { eastRange: "BCDEF", northRange: "UVABCDEFGH" },
    "53U": { eastRange: "KLMNP", northRange: "PQRSTUVABC" },
    "54U": { eastRange: "TUVWX", northRange: "UVABCDEFGH" },
    "55U": { eastRange: "BCDEF", northRange: "PQRSTUVABC" },
    "56U": { eastRange: "KLMNP", northRange: "UVABCDEFGH" },
    "51T": { eastRange: "TUVWXY", northRange: "EFGHJKLMNP" },
    "52T": { eastRange: "BCDEFG", northRange: "KLMNPQRSTU" },
    "53T": { eastRange: "KLMNPQ", northRange: "EFGHJKLMNP" },
    "54T": { eastRange: "TUVWXY", northRange: "KLMNPQRSTU" },
    "55T": { eastRange: "BCDEFG", northRange: "EFGHJKLMNP" },
    "56T": { eastRange: "KLMNPQ", northRange: "KLMNPQRSTU" },
    "51S": { eastRange: "TUVWXY", northRange: "RSTUVABCDE" },
    "52S": { eastRange: "BCDEFG", northRange: "ABCDEFGHJK" },
    "53S": { eastRange: "KLMNPQ", northRange: "RSTUVABCDE" },
    "54S": { eastRange: "TUVWXY", northRange: "ABCDEFGHJK" },
    "55S": { eastRange: "BCDEFG", northRange: "RSTUVABCDE" },
    "56S": { eastRange: "KLMNPQ", northRange: "ABCDEFGHJK" },
    "51R": { eastRange: "TUVWXY", northRange: "GHJKLMNPQR" },
    "52R": { eastRange: "BCDEFG", northRange: "MNPQRSTUVA" },
    "53R": { eastRange: "KLMNPQ", northRange: "GHJKLMNPQR" },
    "54R": { eastRange: "TUVWXY", northRange: "MNPQRSTUVA" },
    "55R": { eastRange: "ABCDEFG", northRange: "GHJKLMNPQR" },
    "56R": { eastRange: "JKLMNPQ", northRange: "MNPQRSTUVA" },
    "51Q": { eastRange: "STUVWXYZ", northRange: "TUVABCDEFG" },
    "52Q": { eastRange: "ABCDEFGH", northRange: "CDEFGHJKLM" },
    "53Q": { eastRange: "JKLMNPQR", northRange: "TUVABCDEFG" },
    "54Q": { eastRange: "STUVWXYZ", northRange: "CDEFGHJKLM" },
    "55Q": { eastRange: "ABCDEFGH", northRange: "TUVABCDEFG" },
    "56Q": { eastRange: "JKLMNPQR", northRange: "CDEFGHJKLM" },
  };

  // zonesGridRangeのzoneの文字列(バンド)だけの配列
  const letters = ["Q", "R", "S", "T", "U"];

  // バンド毎のmin/maxの緯度
  const bandLats = {
    Q: { min: 16, max: 24 },
    R: { min: 24, max: 32 },
    S: { min: 32, max: 40 },
    T: { min: 40, max: 48 },
    U: { min: 48, max: 56 },
  };

  function pickInterval(zoom) {
    // 地理院地図はmaxズームレベルは18
    // googleMapのmaxズームレベルは21
    const zoomLevel = {
      3: 1,
      4: 1,
      5: 1,
      6: 1,
      7: 2,
      8: 2,
      9: 5000,
      10: 2000,
      11: 1000,
      12: 500,
      13: 300,
      14: 100,
      15: 50,
      16: 25,
      17: 10,
      18: 10,
      19: 10,
      20: 10,
      21: 10,
      22: 10,
      23: 10,
    };
    if (zoom < 3) {
      return zoomLevel[3];
    }
    if (zoom > 23) {
      return zoomLevel[23];
    }
    return zoomLevel[zoom];
  }

  // zoneの配列を作成
  // 注釈：zoneの描画対象範囲は、51Q ~ 56U。
  // ex: ['52S', '52T', '53S', '53T', '54S', '54T']
  function createZoneArray(parsedMinUsng, parsedMaxUsng) {
    const minZone = parsedMinUsng.zone.match(/(\d+)([A-Z]+)/);
    const maxZone = parsedMaxUsng.zone.match(/(\d+)([A-Z]+)/);
    const zones = [];
    let startZoneNum;
    let endZoneNum;
    let startZoneChar;
    let endZoneChar;

    if (Number(minZone[1]) >= 51 && Number(minZone[1]) <= 56) {
      startZoneNum = Number(minZone[1]);
    } else {
      startZoneNum = 51;
    }
    if (Number(maxZone[1]) >= 51 && Number(maxZone[1]) <= 56) {
      endZoneNum = Number(maxZone[1]);
    } else {
      endZoneNum = 56;
    }
    if (letters.indexOf(minZone[2]) !== -1) {
      [, , startZoneChar] = minZone;
    } else {
      startZoneChar = "Q";
    }
    if (letters.indexOf(maxZone[2]) !== -1) {
      [, , endZoneChar] = maxZone;
    } else {
      endZoneChar = "U";
    }

    // 画面の右端まで線を描画するため「+1」している
    for (let i = startZoneNum; i <= endZoneNum + 1 && i < 57; i += 1) {
      for (
        let j = letters.indexOf(startZoneChar);
        j <= letters.indexOf(endZoneChar) + 1 && j < letters.length;
        j += 1
      ) {
        zones.push(i + letters[j]);
      }
    }
    return zones;
  }

  // 対象zoneのminGridを返す
  function getMinGrid(parsedUsng, zone) {
    const parsedMinUsng = parsedUsng;
    // targetのZoneが画面上でのminimum zoneより東に位置するか判定し、zone内のmin gridを取得する
    const minZone = parsedMinUsng.zone.match(/(\d+)([A-Z]+)/);
    const targetZone = zone.match(/(\d+)([A-Z]+)/);
    let minGridE;
    let minGridN;

    // minZoneより西のzoneは描画しない
    if (targetZone[1] < minZone[1] || targetZone[2] < minZone[2]) {
      return false;
    }

    let changedMinZone = false;
    // 51Q がzoneのminimum。それ以下の場合は、51Qを使う
    if (minZone[1] < 51 || minZone[1] > 56) {
      minZone[1] = 51;
      changedMinZone = true;
    }
    if (letters.indexOf(minZone[2]) === -1) {
      [minZone[2]] = letters;
      changedMinZone = true;
    }

    // maxZoneの内容がparsedMinUsng.zoneの内容から変更されていたらgridも合わせて変更
    if (changedMinZone) {
      parsedMinUsng.zone = minZone[1] + minZone[2];
      const [gridE] = zonesGridRange[parsedMinUsng.zone].eastRange;
      const [gridN] = zonesGridRange[parsedMinUsng.zone].northRange;
      parsedMinUsng.grid = gridE + gridN;
    }

    // それぞれのzoneの数値部分を比較(ex: 51Sと52Sの場合、51と52を比較)
    if (targetZone[1] === minZone[1]) {
      [minGridE] = parsedMinUsng.grid;
    } else if (targetZone[1] > minZone[1]) {
      [minGridE] = zonesGridRange[zone].eastRange;
    }
    // それぞれのzoneのローマ字部分を比較(ex: 51Sと51Rの場合、SとRを比較)
    if (targetZone[2] === minZone[2]) {
      // northRangeはzoneによって文字が変わるためindex比較で取得する。
      const i = zonesGridRange[parsedMinUsng.zone].northRange.indexOf(
        parsedMinUsng.grid[1],
      );
      minGridN = zonesGridRange[targetZone[0]].northRange[i];
    } else if (targetZone[2] > minZone[2]) {
      [minGridN] = zonesGridRange[zone].northRange;
    }
    return minGridE + minGridN;
  }

  // 対象zoneのmaxGridを返す
  function getMaxGrid(parsedUsng, zone) {
    const parsedMaxUsng = parsedUsng;
    // targetのZoneが画面上でのmaximum zoneより南に位置するか判定し、zone内のmax gridを取得する
    const maxZone = parsedMaxUsng.zone.match(/(\d+)([A-Z]+)/);
    const targetZone = zone.match(/(\d+)([A-Z]+)/);
    let maxGridE;
    let maxGridN;

    let changedMaxZone = false;
    // 56U がzoneのmaximum。それ以上の場合は、56Uを使う
    if (maxZone[1] > 56 || maxZone[1] < 51) {
      maxZone[1] = 56;
      changedMaxZone = true;
    }
    if (letters.indexOf(maxZone[2]) === -1) {
      maxZone[2] = letters[letters.length - 1];
      changedMaxZone = true;
    }

    // maxZoneの内容がparsedMaxUsng.zoneの内容から変更されていたらgridも合わせて変更
    if (changedMaxZone) {
      parsedMaxUsng.zone = maxZone[1] + maxZone[2];
      const [, , , , , gridE] = zonesGridRange[parsedMaxUsng.zone].eastRange;
      const [, , , , , , , , , gridN] =
        zonesGridRange[parsedMaxUsng.zone].northRange;
      parsedMaxUsng.grid = gridE + gridN;
    }

    // maxZoneより北のzoneは描画しないのでエラーする
    if (targetZone[1] > maxZone[1] || targetZone[2] > maxZone[2]) {
      return false;
    }

    // それぞれのzoneの数値部分を比較(ex: 51Sと52Sの場合、51と52を比較)
    if (targetZone[1] === maxZone[1]) {
      [maxGridE] = parsedMaxUsng.grid;
    } else if (targetZone[1] < maxZone[1]) {
      // 配列の5番目 (注釈：eslintのprefer-destructuringルール対応)
      [, , , , , maxGridE] = zonesGridRange[zone].eastRange;
    }
    // それぞれのzoneのローマ字部分を比較(ex: 51Sと51Rの場合、SとRを比較)
    if (targetZone[2] === maxZone[2]) {
      // northRangeはzoneによって文字が変わるためindex比較で取得する。
      const i = zonesGridRange[parsedMaxUsng.zone].northRange.indexOf(
        parsedMaxUsng.grid[1],
      );
      maxGridN = zonesGridRange[targetZone[0]].northRange[i];
    } else if (targetZone[2] < maxZone[2]) {
      // 配列の9番目 (注釈：eslintのprefer-destructuringルール対応)
      [, , , , , , , , , maxGridN] = zonesGridRange[zone].northRange;
    }
    return maxGridE + maxGridN;
  }

  // grid単位の配列作成
  // 注釈1：「52S DA 0000 0000」の「DA」の箇所の配列を作成。ex) [['DA', 'EA', 'FA'],['DB', 'EB', 'FB']]
  // 注釈2：grid(ex.DA)のgrid[0](ex.DAのD)は東具合を意味し、grid[1](ex.DAのA)は北具合を示す
  function createGridCharactersArray(zone, minGrid, maxGrid, isNsDir) {
    const { eastRange, northRange } = zonesGridRange[zone];
    // 南北方向かどうかで親ループと子ループを入れ替える。
    // 南北方向の線を生成する際は、iに eastRange, jに northRange
    // 東西方向の線を生成する際は、iに northRange, jに eastRange
    const primaryRange = isNsDir ? eastRange : northRange;
    const secondaryRange = isNsDir ? northRange : eastRange;
    // 上記のeastRange/northRangeに合わせてgrid文字列(ex.DA)の参照先を変更
    const primaryIndex = isNsDir ? 0 : 1;
    const secondaryIndex = isNsDir ? 1 : 0;

    const grids = [];

    // 格子線(line)のためには、画面の右端まで線を描画するため「+1」が必要
    // 格子情報(point)の描画のためには、「+1」は不要 (「+1」あると処理が重くてズームイン時止まる)
    const primaryRangeFinishCond = primaryRange.indexOf(maxGrid[primaryIndex]);
    const secondaryRangeFinishCond = secondaryRange.indexOf(
      maxGrid[secondaryIndex],
    );
    for (
      let i = primaryRange.indexOf(minGrid[primaryIndex]);
      i <= primaryRangeFinishCond;
      i += 1
    ) {
      if (primaryRange[i]) {
        const grid = [];
        for (
          let j = secondaryRange.indexOf(minGrid[secondaryIndex]);
          j <= secondaryRangeFinishCond;
          j += 1
        ) {
          if (secondaryRange[j]) {
            if (isNsDir) {
              grid.push(eastRange[i] + northRange[j]);
            } else {
              grid.push(eastRange[j] + northRange[i]);
            }
          }
        }
        grids.push(grid);
      }
    }
    return grids;
  }

  // zoneの配列(ex. ['52S', '52T', '53S', '53T', '54S', '54T'])からUTMポイントへ変換
  function createFeatures4zonesOnly(zones) {
    const features = zones.map((zone) => {
      const zoneNumber = Number(zone.slice(0, -1));
      const band = zone.slice(-1);

      const centerLongitude = zoneNumber * 6 - 183;
      const minLongitude = centerLongitude - 3;
      const maxLongitude = centerLongitude + 3;

      const minLatitude = bandLats[band].min;
      const maxLatitude = bandLats[band].max;

      return {
        type: "Feature",
        properties: { description: zone },
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [minLongitude, minLatitude],
              [maxLongitude, minLatitude],
              [maxLongitude, maxLatitude],
              [minLongitude, maxLatitude],
              [minLongitude, minLatitude],
            ],
          ],
        },
      };
    });
    return features;
  }

  // zone単位のグリッドのzone情報用のfeatures作成
  function createPointsFeatures4zonesOnly(zones) {
    const features = zones.map((zone) => {
      const zoneNumber = Number(zone.slice(0, -1));
      const band = zone.slice(-1);

      const centerLongitude = zoneNumber * 6 - 183;
      const minLongitude = centerLongitude - 3;

      const minLatitude = bandLats[band].min;

      // 地理座標を画面座標に変換
      const orgLngLat = { lng: minLongitude, lat: minLatitude };
      const pointPrj = map.project(orgLngLat);
      // 15ピクセル北に移動
      pointPrj.y -= 15;
      // 20ピクセル東に移動
      pointPrj.x += 20;

      // 画面座標を地理座標に戻す
      const newLngLat = map.unproject(pointPrj);

      return {
        type: "Feature",
        properties: { description: zone },
        geometry: {
          type: "Point",
          coordinates: [newLngLat.lng, newLngLat.lat],
        },
      };
    });
    return features;
  }

  // grid単位以下の格子情報を作成するため、各gridに対するfor文実行時に必要な開始条件と終了条件を返す
  function findStartAndEndConditions(
    prefix,
    usng4minAndMax,
    interval,
    is4line,
  ) {
    const minPrefix = usng4minAndMax.min.prefix;
    let minEasting = usng4minAndMax.min.easting;
    let minNorthing = usng4minAndMax.min.northing;
    const maxPrefix = usng4minAndMax.max.prefix;
    let maxEasting = usng4minAndMax.max.easting;
    let maxNorthing = usng4minAndMax.max.northing;

    if (is4line) {
      // 線描画のみを対象とした処理
      // grid内で線を画面の端まで出すためひと区画画面の外のutmポイントを対象にする
      minEasting =
        minEasting - interval >= 0 ? minEasting - interval : minEasting;
      minNorthing =
        minNorthing - interval >= 0 ? minNorthing - interval : minNorthing;
      maxEasting =
        maxEasting + interval <= 9999 ? maxEasting + interval : maxEasting;
      maxNorthing =
        maxNorthing + interval <= 9999 ? maxNorthing + interval : maxNorthing;
    }

    let startE;
    let startN;
    let endE;
    let endN;
    const minPrefixSplit = minPrefix.split(" ");
    const maxPrefixSplit = maxPrefix.split(" ");
    const targetPrefixSplit = prefix.split(" ");
    if (prefix === minPrefix && prefix === maxPrefix) {
      // 描画対象が一つのgridに収まる場合
      startE = minEasting;
      endE = maxEasting;
      startN = minNorthing;
      endN = maxNorthing;
    } else if (prefix === minPrefix) {
      startE = minEasting;
      startN = minNorthing;
    } else if (prefix === maxPrefix) {
      endE = maxEasting;
      endN = maxNorthing;
    }

    if (prefix !== minPrefix) {
      if (targetPrefixSplit[0] === minPrefixSplit[0]) {
        if (targetPrefixSplit[1][0] === minPrefixSplit[1][0]) {
          // zoneが同じでtargetのgridがminのgridと南北方向に同じ並びにある場合
          startE = minEasting;
          startN = 0;
        } else if (minPrefixSplit[1][1] === targetPrefixSplit[1][1]) {
          // 南北方向に同じ並びにある場合
          startE = 0;
          startN = minNorthing;
        } else {
          startE = 0;
          startN = 0;
        }
      } else {
        startE = 0;
        startN = 0;
      }
    }

    if (prefix !== maxPrefix) {
      if (targetPrefixSplit[0] === maxPrefixSplit[0]) {
        if (targetPrefixSplit[1][0] === maxPrefixSplit[1][0]) {
          // zoneが同じでmaxのgridがminのgridと南北方向に同じ並びにある場合
          endE = maxEasting;
          endN = 9999;
        } else if (maxPrefixSplit[1][1] === targetPrefixSplit[1][1]) {
          // 南北方向に同じ並びにある場合
          endE = 9999;
          endN = maxNorthing;
        } else {
          endE = 9999;
          endN = 9999;
        }
      } else {
        endE = 9999;
        endN = 9999;
      }
    }

    return { startE, startN, endE, endN };
  }

  function formatNumber(num) {
    return num.toString().padStart(4, "0");
  }

  function createEntry(prefix, outerNum, innerNum, isNsDir) {
    return isNsDir
      ? `${prefix} ${formatNumber(outerNum)} ${formatNumber(innerNum)}`
      : `${prefix} ${formatNumber(innerNum)} ${formatNumber(outerNum)}`;
  }

  // 端まで線を伸ばすためのvalueを返す
  function createEdgeValue(prefix, num, isNsDir) {
    return isNsDir
      ? `${prefix} ${formatNumber(num)} 9999`
      : `${prefix} 9999 ${formatNumber(num)}`;
  }

  function createEdgeLine(lastRowOfGrids, isNsDir) {
    const lastEdgeUtmPoints = lastRowOfGrids.map((grid) => {
      if (isNsDir) {
        return grid.replace(/(\d{4}) (\d{4})$/, "9999 $2");
      }
      return grid.replace(/(\d{4}) (\d{4})$/, "$1 9999");
    });
    return lastEdgeUtmPoints;
  }

  // 各zoneのgrid(ex. '52S': ['DA', 'DB'])の配列からUTMポイントへ変換
  // (ex. '52S': ['52S DA 0000 0000', '52S DB 0000 0000'])
  function createUtmPointsArray4lines(
    zoneGrids,
    interval,
    usng4minAndMax,
    isNsDir,
  ) {
    const newObj = {};
    Object.keys(zoneGrids).forEach((zone) => {
      zoneGrids[zone].forEach((grids, index, arr) => {
        // grid単位の格子のためのUTMポイント作成
        if (interval === 2) {
          const utmPoints = grids.map((grid) => `${zone} ${grid} 0000 0000`);

          // 最後の配列時はgridの端の線も出すため配列要素を追加する。
          // 注釈：0000 0000はgridの南西端を指すので、そのままだと最北端や最東端への線が描画できない。
          if (isNsDir) {
            const lastGridNWPoint = utmPoints[utmPoints.length - 1].replace(
              /\b0000\b(?=$)/,
              "9999",
            );
            utmPoints.push(lastGridNWPoint);
          } else {
            const lastGridESPoint = utmPoints[utmPoints.length - 1].replace(
              /\b0000\b(?=\s)/,
              "9999",
            );
            utmPoints.push(lastGridESPoint);
          }
          if (!newObj[zone]) {
            newObj[zone] = [];
          }
          newObj[zone].push(utmPoints);

          // zoneの最後のgrid配列を追加後、端を追加するためもう一つ最後に配列追加する
          // 注釈：0000 0000はgridの南西端を指すので、そのままだと最北端や最東端への線が描画できない。
          const lastRowOfGrids = newObj[zone][newObj[zone].length - 1];
          if (index === arr.length - 1) {
            newObj[zone].push(createEdgeLine(lastRowOfGrids, isNsDir));
          }
        }

        // grid単位以下の格子のためのUTMポイント作成
        if (interval > 2) {
          // 各zoneで扱うgridと合わせてprefix(= zone + prefix)の配列作成
          const prefixes = grids.map((grid) => `${zone} ${grid}`);

          const conditions4zone = {};
          // 各grid内での最小値と最大値を取得
          prefixes.forEach((prefix) => {
            const conditions = findStartAndEndConditions(
              prefix,
              usng4minAndMax,
              interval,
              true,
            );
            conditions4zone[prefix] = [];
            conditions4zone[prefix] = conditions;
          });

          if (!newObj[zone]) {
            newObj[zone] = [];
          }

          let outerStart;
          let outerEnd;
          let innnerStart;
          let innnerEnd;
          // 各グリッド内で必要な線のために必要なUTMポイントを作成する
          Object.entries(conditions4zone).forEach(([prefix, value]) => {
            // 南北方向かどうかで親ループと子ループを入れ替える。
            outerStart = isNsDir ? value.startE : value.startN;
            outerEnd = isNsDir ? value.endE : value.endN;
            innnerStart = isNsDir ? value.startN : value.startE;
            innnerEnd = isNsDir ? value.endN : value.endE;

            const lastIterationStart = innnerEnd - (innnerEnd % interval);
            for (let i = outerStart; i <= outerEnd; i += interval) {
              const listInLine = [];
              for (let j = innnerStart; j <= innnerEnd; j += interval) {
                listInLine.push(createEntry(prefix, i, j, isNsDir));
                if (j === lastIterationStart && innnerEnd === 9999) {
                  listInLine.push(createEdgeValue(prefix, i, isNsDir));
                }
              }
              newObj[zone].push(listInLine);
            }

            // zoneの最後のgrid配列を追加後、端を追加するためもう一つ最後に配列追加する
            // 注釈：0000 0000はgridの南西端を指すので、そのままだと最北端や最東端への線が描画できない。
            const lastRowOfGrids = newObj[zone][newObj[zone].length - 1];
            if (outerEnd === 9999 && index === arr.length - 1) {
              newObj[zone].push(createEdgeLine(lastRowOfGrids, isNsDir));
            }
          });
        }
      });
    });
    return newObj;
  }

  // 描画対象：grid単位以下
  function createFeatures4line(utmPointsArray) {
    const features = [];
    let latlon;
    let latlon2;
    Object.keys(utmPointsArray).forEach((zone) => {
      const zoneGrids = utmPointsArray[zone];
      for (let i = 0; i < zoneGrids.length && zoneGrids.length > 1; i += 1) {
        for (
          let j = 0;
          j < zoneGrids[i].length - 1 && zoneGrids[i].length > 1;
          j += 1
        ) {
          latlon = usng.USNGtoLL(zoneGrids[i][j]);
          latlon2 = usng.USNGtoLL(zoneGrids[i][j + 1]);
          // 地球の丸みを考慮して線を引くために、geodesic（大圏）ルートを作成
          const point1 = point([latlon.lon, latlon.lat]);
          const point2 = point([latlon2.lon, latlon2.lat]);
          const geodesicLine = greatCircle(point1, point2);
          features.push({
            type: "Feature",
            geometry: {
              type: "LineString",
              coordinates: geodesicLine.geometry.coordinates,
            },
            properties: { value: zoneGrids[i][j] },
          });
        }
      }
    });
    return features;
  }

  // UTMグリッドの格子の細かさの判定にはintervalを使う
  function createFeatures4point(utmPointsArray, interval) {
    const features = [];
    let latlon;
    for (let i = 0; i < utmPointsArray.length; i += 1) {
      latlon = usng.USNGtoLL(utmPointsArray[i]);
      let zoneInfo;
      // intervalの1は、grid単位の描画
      // ex: 52SDA
      if (interval === 2) {
        zoneInfo = utmPointsArray[i].split(" ").slice(0, 2).join("");
      } else if (interval === 1) {
        // intervalの2は、zone単位の描画
        // ex: 52S
        zoneInfo = utmPointsArray[i].split(" ").slice(0, 1).join("");
      } else {
        // それ以外のintervalは、grid単位以下での描画
        // ex: 52SDA00001000
        zoneInfo = utmPointsArray[i].replace(/\s/g, "");
      }

      // 地理座標を画面座標に変換
      const pointPrj = map.project(latlon);
      // 15ピクセル北に移動
      pointPrj.y -= 15;
      if (interval === 2) {
        // 40ピクセル東に移動
        pointPrj.x += 40;
      } else if (interval === 1) {
        // 20ピクセル東に移動
        pointPrj.x += 20;
      } else {
        // 80ピクセル東に移動
        pointPrj.x += 80;
      }
      // 画面座標を地理座標に戻す
      const newLngLat = map.unproject(pointPrj);

      features.push({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [newLngLat.lng, newLngLat.lat],
        },
        properties: {
          description: zoneInfo,
        },
      });
    }
    return features;
  }

  const parseInput = (input) => {
    const [zone, grid, easting, northing] = input.split(" ");
    return { zone, grid, easting, northing };
  };

  let interval = pickInterval(Math.round(map.getZoom()));

  // UTMグリッド線を更新
  function updateUtm() {
    const bounds = map.getBounds();
    const minUsng = usng.LLtoUSNG(bounds.getSouth(), bounds.getWest(), 4);
    const maxUsng = usng.LLtoUSNG(bounds.getNorth(), bounds.getEast(), 4);
    const parsedMinUsng = parseInput(minUsng);
    const parsedMaxUsng = parseInput(maxUsng);
    interval = pickInterval(Math.round(map.getZoom()));

    // 画面上のutmポイントを扱いやすい形に整形
    // parsedMinUsngのprefix部分取得
    const minPrefix = `${parsedMinUsng.zone} ${parsedMinUsng.grid}`;
    // parsedMaxUsngのprefix部分取得
    const maxPrefix = `${parsedMaxUsng.zone} ${parsedMaxUsng.grid}`;
    // それぞれのeasting/northingも取得
    const minEasting = Math.floor(parsedMinUsng.easting / interval) * interval;
    const minNorthing =
      Math.floor(parsedMinUsng.northing / interval) * interval;
    let maxEasting = Math.ceil(parsedMaxUsng.easting / interval) * interval;
    maxEasting = maxEasting !== 10000 ? maxEasting : 9999;
    let maxNorthing = Math.ceil(parsedMaxUsng.northing / interval) * interval;
    maxNorthing = maxNorthing !== 10000 ? maxNorthing : 9999;
    const usng4minAndMax = {
      min: { prefix: minPrefix, easting: minEasting, northing: minNorthing },
      max: { prefix: maxPrefix, easting: maxEasting, northing: maxNorthing },
    };

    // 画面に映るzoneをまとめた配列作成
    const zones = createZoneArray(parsedMinUsng, parsedMaxUsng);

    // 描画対象の全てのzone毎のgrid単位の配列(南北)
    const nsGrids4allZones = {};
    // 描画対象の全てのzone毎のgrid単位の配列(東西)
    const ewGrids4allZones = {};
    // 描画対象の全てのzone毎の格子情報用の配列(南北)
    const gridPoints4allZones = {};
    // // 描画対象の全てのzone毎の格子情報用の配列(東西)
    zones.forEach((zone) => {
      const minGrid = getMinGrid(parsedMinUsng, zone);
      const maxGrid = getMaxGrid(parsedMaxUsng, zone);
      // minGridもしくはmaxGridどちらかがない場合、対象外のgridとして処理をスキップ
      if (!minGrid || !maxGrid) return;

      // 格子線用
      // 南北:zone毎のgrid単位の配列作成
      const nsGrids4zone = createGridCharactersArray(
        zone,
        minGrid,
        maxGrid,
        true,
      );
      nsGrids4allZones[zone] = nsGrids4zone;
      // 東西:zone毎のgrid単位の配列作成
      const ewGrids4zone = createGridCharactersArray(
        zone,
        minGrid,
        maxGrid,
        false,
      );
      ewGrids4allZones[zone] = ewGrids4zone;

      // 格子情報描画用(点情報)
      // zone毎のgrid単位の配列作成(線と違って方角関係ないので一つns/ew分け内で良い)
      const grids4points = createGridCharactersArray(
        zone,
        minGrid,
        maxGrid,
        true,
        false,
      );
      gridPoints4allZones[zone] = grids4points;
    });

    let nsUtmPointsArray = [];
    let ewUtmPointsArray = [];

    let features4zoneOnly = [];
    let pointFetures4zoneOnly = [];

    let lineFeatures;
    // 描画対象：zone単位
    if (interval === 1) {
      features4zoneOnly = createFeatures4zonesOnly(zones);
      pointFetures4zoneOnly = createPointsFeatures4zonesOnly(zones);

      // グリッド以下の格子は消す
      lineFeatures = [];
    } else {
      // 描画対象：grid単位から下
      // latLngにする前のUTMの点情報の配列を作成
      nsUtmPointsArray = createUtmPointsArray4lines(
        nsGrids4allZones,
        interval,
        usng4minAndMax,
        true,
      );
      ewUtmPointsArray = createUtmPointsArray4lines(
        ewGrids4allZones,
        interval,
        usng4minAndMax,
        false,
      );
      // UTM線の更新(南北線)
      // （ここで点情報を線情報にしてる）
      const nsFeature = createFeatures4line(nsUtmPointsArray);
      // UTM線の更新(東西線)
      const ewFeatures = createFeatures4line(ewUtmPointsArray);
      // 一つのfeaturesにまとめる
      lineFeatures = [...nsFeature, ...ewFeatures];

      // zone情報は消す
      features4zoneOnly = [];
      pointFetures4zoneOnly = [];
    }

    // UTMグリッドの格子情報を描画するためのUTMの配列を作成
    // zone毎にオブジェクトから値（配列）を取り出し、一つの大きな配列に結合
    const combinedArray = [
      ...Object.values(nsUtmPointsArray),
      ...Object.values(ewUtmPointsArray),
    ].flat(2);
    // 配列から重複を削除、および9999を含むものは排除
    // 注釈：zoneの格子の情報は常に南西位置に描画する。そのため、9999を含むものは南西以外のため不要。
    const utmPointsArray = [...new Set(combinedArray)].filter(
      (item) => !item.includes("9999"),
    );

    // grid単位/grid単位以下のUTMポイントからfeaturesを作成
    const pointFeatures = createFeatures4point(utmPointsArray, interval);

    // ゾーン単位の描画格子の更新
    map.getSource("zoneLine").setData({
      type: "FeatureCollection",
      features: features4zoneOnly,
    });
    // ゾーンの表示情報の更新
    map.getSource("zonePoints").setData({
      type: "FeatureCollection",
      features: pointFetures4zoneOnly,
    });
    // グリッド単位以下の描画格子の更新
    map.getSource("utmLine").setData({
      type: "FeatureCollection",
      features: lineFeatures,
    });
    // グリッドの表示情報の更新
    map.getSource("utmPoints").setData({
      type: "FeatureCollection",
      features: pointFeatures,
    });
  }

  // ここから地図追加部分スタート
  if (!map.getSource("utmLine")) {
    map.addSource("utmLine", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });
    map.addSource("utmPoints", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });
    map.addSource("zoneLine", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });
    map.addSource("zonePoints", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });
    let initialSet = false;
    if (!initialSet) {
      updateUtm();
      initialSet = true;
    }

    map.addLayer({
      id: "utmLine",
      type: "line",
      source: "utmLine",
      paint: {
        "line-color": "red",
        "line-width": 2,
        "line-opacity": 1.0,
        "line-dasharray": [2, 2],
      },
      layout: {
        visibility: "visible",
      },
    });
    map.addLayer({
      id: "utmPoints",
      type: "symbol",
      source: "utmPoints",
      layout: {
        "text-field": ["get", "description"],
        "text-size": 15,
        "text-font": ["Arial Unicode MS Bold"],
        visibility: "visible",
      },
      paint: {
        "text-color": "red",
        "text-halo-color": "white",
        "text-halo-width": 2,
      },
    });
    map.addLayer({
      id: "zonePoints",
      type: "symbol",
      source: "zonePoints",
      layout: {
        "text-field": ["get", "description"],
        "text-size": 15,
        "text-font": ["Arial Unicode MS Bold"],
        visibility: "visible",
      },
      paint: {
        "text-color": "red",
        "text-halo-color": "white",
        "text-halo-width": 2,
      },
    });
    map.addLayer({
      id: "zoneLine",
      type: "line",
      source: "zoneLine",
      layout: {
        visibility: "visible",
      },
      paint: {
        "line-color": "red",
        "line-width": 2,
        "line-opacity": 1.0,
      },
    });
    map.on("moveend", updateUtm);
  }
}
