/* eslint-disable no-underscore-dangle */
import { Popup } from "maplibre-gl";
import { layerName } from "../common/name";
import layerOpacity from "../consts/layer_opacity";
import BaseLayer from "./base_layer";
import { loadIcon } from "./geojson_utils";

/**
 * @typedef VectorLayerParameter
 * @property {number} minZoom レイヤーを表示する最小のズームレベル. これ以上地図を引くと表示が消える
 * @property {number} maxZoom レイヤーを表示する最大のズームレベル. これ以上地図をよると表示が消える
 * @property {StyleJs} styleJs 表示をカスタマイズするためのstyle
 */

/**
 * @typedef FillPattern
 * @property {string} name mapに登録する名前
 * @property {string} svg 塗りつぶしを表現するsvg
 */

/**
 * @typedef BaseStyleJs
 * @property {string[]} iconUrls 使用するアイコンURL
 * @property {FillPattern[]} fillPatterns 塗りつぶしに適用するsvg定義
 * @property {object} style アイコンの定義などを指定するスタイル, Maplibreのスタイルフォーマットに準拠 ref. https://maplibre.org/maplibre-style-spec/layers/
 * @property {object[]} styles styleのArray
 * @property {(properties: object) => string} popup 地物をクリックしたときに表示するポップアップの中身を作るfunction
 */

/**
 * ベースとなるベクトルレイヤークラス
 */
export default class VectorLayer extends BaseLayer {
  /** @type {VectorLayerParameter} */
  parameter;

  /** @type {import("maplibre-gl").TypedStyleLayer[]} レイヤー群 */
  layers = [];

  /** @type {import("@maplibre/maplibre-gl-style-spec").LayerSpecification[]} map.addLayerに渡すレイヤー定義 */
  layerDefinitions = [];

  /** @type {maplibregl.Popup} popup 地図に表示するポップアップ, レイヤー削除時に消すために保持するもの */
  popup;

  /** @type {boolean} 作図系の処理が動いていることを表すフラグ. trueの時はマウスイベントに反応させないようにする */
  isDrawing = false;

  /** @type {(MapMouseEvent) => void} マウスがfeatureに入った時に動作させるfunction */
  mouseEnterEventFunction = (e) => {
    if (this.isDrawing) {
      return;
    }

    const canvas = this.map.getCanvas();
    canvas.style.cursor = "pointer";
    e.preventDefault();
  };

  /** @type {(MapMouseEvent) => void} マウスがfeatureから離れた時に動作させるfunction */
  mouseLeaveEventFunction = (e) => {
    if (this.isDrawing) {
      return;
    }

    // このレイヤーの中にある表示用レイヤーのレイヤー名一覧を作る
    const layers = this.layerDefinitions.map((ld) => ld.id) || [];
    const featuresAroundPointer = this.map.queryRenderedFeatures(e.point, {
      layers,
    });

    // 現在のマウスポインタ上にレイヤー関連のアイコンがなかったらマウスカーソルを戻す
    if (featuresAroundPointer.length === 0) {
      const canvas = this.map.getCanvas();
      canvas.style.cursor = "grab";
      e.preventDefault();
    }
  };

  /**
   * 地図にレイヤーを追加する一連の処理
   *
   * @param {maplibregl.Map} map
   */
  addLayer(map) {
    this.makeLayerDefinitions();

    const promisesForIcon = this.addSymbolIcons(map);
    const promisesForPattern = this.addFillPatterns(map);

    // TODO: これはaddLayerの前にPromiseが解決してしまうな?
    return Promise.all(promisesForIcon.concat(promisesForPattern)).then(
      () => {
        for (let i = 0; i < this.layerDefinitions.length; i += 1) {
          map.addLayer(this.layerDefinitions[i]);

          // クリック時にポップアップ発動
          if (this.parameter.styleJs.popup) {
            this.addEvent(
              "click",
              this.layerDefinitions[i].id,
              this.onClickPopup(this.parameter.styleJs.popup),
            );
          }

          // マウスカーソル移動時のカーソルポインター切り替え
          this.addEvent(
            "mouseenter",
            this.layerDefinitions[i].id,
            this.mouseEnterEventFunction,
          );
          this.addEvent(
            "mouseleave",
            this.layerDefinitions[i].id,
            this.mouseLeaveEventFunction,
          );
        }

        // 作図がON/OFFされた時のイベント
        this.addMapEvent("start_drawing", () => {
          this.isDrawing = true;
        });

        this.addMapEvent("end_drawing", () => {
          this.isDrawing = false;
        });
      },
      (error) => {
        throw error;
      },
    );
  }

  /**
   * レイヤーを削除
   *
   * sourceの削除やイベントの削除はBaseLayer::removeがやってくれる
   */
  removeLayer() {
    // popupを閉じる
    this.closePopup();

    this.layerDefinitions.forEach((layerDefinition) => {
      this.map.removeLayer(layerDefinition.id);
    });
  }

  /**
   * mapにiconのimageを登録する
   *
   * @param {maplibregl.Map} map
   * @returns {Promise[]} 登録するimageごとに発行されるpromiseのarray
   */
  addSymbolIcons(map) {
    let iconUrls = [];
    if (
      this.parameter.styleJs.iconUrls &&
      this.parameter.styleJs.iconUrls.length && 
      this.parameter.styleJs.iconUrls[0] !== 'getFromData'
    ) {
      iconUrls = this.parameter.styleJs.iconUrls;
    } else if (
      this.parameter.styleJs.iconUrls &&
      this.parameter.styleJs.iconUrls.length && 
      this.parameter.styleJs.iconUrls[0] === 'getFromData' &&
      this.parameter.geojson &&
      this.parameter.geojson.features

    ) {
      this.parameter.geojson.features.forEach((feature) => {
        const url = feature.properties && feature.properties._iconUrl ? feature.properties._iconUrl : null;
        if(url && !iconUrls.includes(url)) {
          iconUrls.push(url);
        }

        if (feature.properties && feature.properties.isStickyNote) {
          const stickyNoteThumbnailUrl = feature.properties._stickyNoteThumbnail ? feature.properties._stickyNoteThumbnail : null;
            if (stickyNoteThumbnailUrl) {
              iconUrls.push(stickyNoteThumbnailUrl);
            }
          }
        });
    } else {
      return [];
    }

    // loadIconの結果を把握するために, promiseを作ってarrayに格納
    const promises = [];
    // forEachだと実行を待たずにreturnしてしまうので, 古風なforループでやる
    for (let i = 0; i < iconUrls.length; i += 1) {
      const url = iconUrls[i];

      // すでに登録されていたらskip
      if (map.hasImage(url)) {
        break;
      }

      promises.push(loadIcon(map, url));
    }

    return promises;
  }

  /**
   * mapにiconのimageを登録する
   *
   * svgで指定された塗りつぶしパターンをimageとしてmapに登録する
   *
   * @returns {Promise[]} 登録すpatternごとに発行されるpromiseのarray
   */
  addFillPatterns(map) {
    if (
      !this.parameter.styleJs.fillPatterns ||
      !this.parameter.styleJs.fillPatterns
    ) {
      return [];
    }

    // ポリゴンの中を塗りつぶすパターンの登録
    // styleに書いてあるsvgをimageとしてloadして使う
    const promises = [];
    for (let i = 0; i < this.parameter.styleJs.fillPatterns.length; i += 1) {
      const pattern = this.parameter.styleJs.fillPatterns[i];
      promises.push(
        new Promise((resolve, reject) => {
          const image = new Image();

          image.onload = () => {
            map.addImage(pattern.name, image);
            resolve();
          };
          image.onerror = (e) => reject(e);

          image.src = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(
            pattern.svg,
          )}`;
        }),
      );
    }

    return promises;
  }

  /**
   * レイヤー追加で使うレイヤー定義を作る
   *
   * geojsonが持っているfeatureの種類によって, 複数のレイヤーを追加することがある. それぞれについて定義を作る.
   * 具体的には, styleに指定されたkey(line/symbol/polygonなど)それぞれについて定義を一つ生成する.
   */
  makeLayerDefinitions() {
    // styleJs.styleについて
    if (this.parameter.styleJs.style) {
      Object.keys(this.parameter.styleJs.style).forEach((type) => {
        this.layerDefinitions.push({
          id: `${layerName(this.layerId)}-${type}`,
          source: this.sourceName,
          minzoom: this.parameter.minZoom || this.map.getMinZoom(),
          maxzoom: this.parameter.maxZoom || this.map.getMaxZoom(),
          type,
          ...this.parameter.styleJs.style[type], // style.jsの中にある"style"プロパティの中身をそのまま入れる
        });
      });
    }

    // style.styles[]について
    if (
      this.parameter.styleJs.styles &&
      Array.isArray(this.parameter.styleJs.styles)
    ) {
      this.parameter.styleJs.styles.forEach((style, i) => {
        Object.keys(style).forEach((type) => {
          this.layerDefinitions.push({
            id: `${layerName(this.layerId)}-styleArray${i}-${type}`,
            source: this.sourceName,
            minzoom: this.parameter.minZoom || this.map.getMinZoom(),
            maxzoom: this.parameter.maxZoom || this.map.getMaxZoom(),
            type,
            ...style[type], // style.jsの中にある"style"プロパティの中身をそのまま入れる
          });
        });
      });
    }
  }

  /**
   * レイヤークリックでポップアップを出すfunction
   * @param {maplibregl.Map} map
   * @param {function} contentGenerator popupの内容を作るためのfunction
   * @returns `map.on('click', function)` で登録できるfunction
   */
  onClickPopup(contentGenerator) {
    return (e) => {
      if (this.isDrawing) {
        return;
      }

      const coordinates = e.lngLat;
      const { properties } = e.features[0];

      const deserializedProperties = {};
      const propertyKeys = Object.keys(properties);
      for (let j = 0; j < propertyKeys.length; j += 1) {
        const key = propertyKeys[j];

        // valueが `[` や `{` で始まっている => array/objectが入っているとき.
        // ここにあるvalueの中身は全てstring/numberなので, contentGeneratorの中でarray/objectとして使えるように
        // json.parseを行う
        if (
          typeof properties[key] === "string" &&
          properties[key].match(/^[[{]/)
        ) {
          deserializedProperties[key] = JSON.parse(properties[key]);
        } else {
          deserializedProperties[key] = properties[key];
        }
      }

      const content = contentGenerator(deserializedProperties);
      // contentに何か入ってたらポップアップを作る => contentが空の時はポップアップを出さない
      if (content) {
        this.popup = new Popup()
          .setLngLat(coordinates)
          .setHTML(content)
          .setMaxWidth("600px");

        this.popup.addTo(this.map);
      }
    };
  }

  /**
   * レイヤーに対するイベントを追加する
   *
   * @type {maplibregl.MapLayerEventType} eventType 'click'など,発動させたいイベントタイプ
   * @param {(maplibregl.MapMouseEvent?) => void} func 発動させる処理が記述されたfunction
   * @param {number?} styleIndex イベントを発動させるスタイルレイヤーを限定させる場合に番号を指定. 指定がない場合は全てのスタイルレイヤーに適用
   */
  addLayerEvent(eventType, func, styleIndex) {
    if (!this.layerDefinitions[styleIndex]) {
      throw new Error(
        `指定された番号 ${styleIndex} はスタイル定義に存在しない.`,
      );
    }

    if (styleIndex) {
      // 番号指定時はそこだけに適用
      this.addEvent(eventType, this.layerDefinitions[styleIndex].id, func);
    } else {
      // 番号が指定されてない場合は全てに適用
      this.layerDefinitions.forEach((d) => {
        this.addEvent(eventType, d.id, func);
      });
    }
  }

  /**
   * レイヤーに対するイベントを追加する
   *
   * @type {maplibregl.MapLayerEventType} eventType 'click'など,発動させたいイベントタイプ
   * @param {string} layerId
   * @param {(maplibregl.MapMouseEvent?) => void} func 発動させる処理が記述されたfunction
   */
  addEvent(eventType, layerId, func) {
    // 番号指定時はそこだけに適用
    this.map.on(eventType, layerId, func);
    this.eventHandlers.push({
      type: eventType,
      id: layerId,
      func,
    });
  }

  /**
   * Map全体に対するイベントを追加する
   *
   * @type {maplibregl.MapEventType} eventType
   * @param {(maplibregl.MapMouseEvent?) => void} func
   */
  addMapEvent(eventType, func) {
    this.map.on(eventType, func);
    this.eventHandlers.push({
      type: eventType,
      func,
    });
  }

  /**
   * レイヤーの透過度を変更
   * @param {Number} value 透過度 (1:透過しない, 0:透過して見えない)
   */
  setOpacity(value) {
    this.layerDefinitions.forEach((definition) => {
      layerOpacity[definition.type].forEach((type) => {
        this.map.setPaintProperty(definition.id, type, value);
      });
    });
  }

  /**
   * popupを閉じる
   */
  closePopup() {
    if (this.popup) {
      this.popup.remove();
    }
  }
}
