import { pointsWithinPolygon } from "@turf/turf";

/**
 * 地図上にポリゴンを書いて,そのポリゴンの中にあるfeatureを取り出すためのコントローラー
 */
export default class FeatureSelectControl {
  /** @type {maplibregl.Map} */
  map;

  /** @type {HTMLElement} 地図上にコントロールを描画するためのコンテナ.このコントロールは表示される必要がないので空divを作る */
  container;

  /** @type {MapboxDraw} */
  mapboxDraw;

  /** @type {GeoJSON} features 抽出対象とするpoint */
  geojson = {
    type: "FeatureCollection",
    features: [],
  };

  /**
   * constructor
   *
   * @param {MapboxDraw} mapboxDraw
   */
  constructor(mapboxDraw) {
    this.mapboxDraw = mapboxDraw;
  }

  /**
   * 計測コントロール生成, mapから `new DrawControl()` すると呼ばれる
   * @param {maplibregl.Map} map
   * @returns コントロールとして表示するHTMLElement
   */
  onAdd(map) {
    this.map = map;

    map.on("load", () => {
      // activateのイベントハンドラー
      map.on("feature-select-input", (e) => {
        this.#onEnable();
        this.geojson.features = e.features;
        this.mapboxDraw.changeMode(this.mapboxDraw.modes.DRAW_POLYGON);
        const canvas = this.map.getCanvas();
        canvas.style.cursor = "crosshair";
      });
    });

    // containerの中身を作る
    // ボタンなどは不要なので,空divで
    this.container = document.createElement("div");
    this.container.style = "display: none";

    return this.container;
  }

  /**
   * ポリゴンを書いた時のイベントハンドラー
   *
   * DrawEventDispatcherから呼び出される
   *
   * @param {event} e
   */
  onDrawCreate(e) {
    if (!this.geojson.features || !this.geojson.features.length) {
      // 抽出対象がなければ何もしない
      this.onDisable(e);
      this.map.fire("feature-select-result", { result: [] });
      return;
    }
    const polygon = e.features[0];

    // 描かれたポリゴンの中にあるfeatureを抽出. turfが提供するfunctionを使っている
    const result = pointsWithinPolygon(this.geojson, polygon);

    this.onDisable(e);

    // 結果はメッセージに入れて送る
    this.map.fire("feature-select-result", { result: result.features });

    // 一度ポリゴンを書いたらこのコントロールは役割を終える
    // "change-control"イベントを発火して, 作図系コントロールが何も動いてない状態に戻す
    this.map.fire("change-control");
  }

  /**
   * コントロールが有効になった時に行う処理
   */
  #onEnable() {
    // DrawEventDispatcherなど,他のコントロールに通知
    // 他のコントロールはこれを受け取ったら自分が動かないようにする
    this.map.fire("change-control", {
      controlName: "FeatureSelectControl",
      callback: () => {
        const canvas = this.map.getCanvas();
        canvas.style.cursor = "crosshair";
      },
    });
  }

  /**
   * コントロールが無効になった時に行う処理
   *
   * 無効になった後,再度有効にするためには"feature-select-input"イベントを発火させる
   *
   * @param {object?} e イベント
   */
  onDisable(e) {
    // 書いている途中のオブジェクトがあったら削除
    // このオブジェクトについて何もしないとイベントが無限ループする
    if (e) {
      this.mapboxDraw.delete(e.features[0].id);
    }

    // 初期状態に戻す
    this.mapboxDraw.changeMode(this.mapboxDraw.modes.SIMPLE_SELECT);
    this.geojson.features = [];
    const canvas = this.map.getCanvas();
    canvas.style.cursor = "grab";
  }
}
