export default function addLatLngGrid(map) {
  function pickInterval(zoom) {
    // 地理院地図はmaxズームレベルは18
    // googleMapのmaxズームレベルは21
    const zoomLevel = {
      3: 20,
      4: 10,
      5: 5,
      6: 3,
      7: 1.5,
      8: 1,
      9: 0.5,
      10: 0.3,
      11: 0.15,
      12: 0.05,
      13: 0.03,
      14: 0.015,
      15: 0.008,
      16: 0.005,
      17: 0.003,
      18: 0.0015,
      19: 0.0008,
      20: 0.0005,
      21: 0.0003,
      22: 0.00015,
      23: 0.00008,
    };
    if (zoom < 3) {
      return zoomLevel[3];
    }
    if (zoom > 23) {
      return zoomLevel[23];
    }
    return zoomLevel[zoom];
  }

  function roundNum(target, decimalPlace) {
    const multiplier = 10 ** decimalPlace;
    return Math.round(target * multiplier) / multiplier;
  }

  function getDecimalDigit(number) {
    if (Math.floor(number) === number) return 0;
    return number.toString().split(".")[1].length || 0;
  }

  let interval = pickInterval(Math.round(map.getZoom()));
  let roundDecimal = 0;
  const offsetPixels = 30;

  function updateLonPoints() {
    interval = pickInterval(Math.round(map.getZoom()));
    roundDecimal = getDecimalDigit(interval);

    const bounds = map.getBounds();
    const minLon = Math.floor(bounds.getWest());
    const maxLon = Math.ceil(bounds.getEast() * 100) / 100;
    const minLat = Math.floor(bounds.getSouth());
    const maxLat = Math.ceil(bounds.getNorth() * 100) / 100;

    const topGeo = map.unproject([0, offsetPixels]);
    const leftGeo = map.unproject([offsetPixels, 0]);

    // lngの数値情報の更新
    const pointLonFeatures = [];
    for (let lon = 110; lon <= maxLon; lon += interval) {
      if (lon >= minLon) {
        const lng = roundNum(lon, roundDecimal);
        pointLonFeatures.push({
          type: "Feature",
          properties: {
            description: `${lng}`,
          },
          geometry: {
            type: "Point",
            coordinates: [lng, topGeo.lat],
          },
        });
      }
    }

    // latの数値情報の更新
    const pointLatFeatures = [];
    for (let lat = 5; lat <= maxLat; lat += interval) {
      if (lat >= minLat) {
        const newLat = roundNum(lat, roundDecimal);
        pointLatFeatures.push({
          type: "Feature",
          properties: {
            description: `${newLat}`,
          },
          geometry: {
            type: "Point",
            coordinates: [leftGeo.lng, newLat],
          },
        });
      }
    }
    // 緯度経度線の更新
    const graticule = [];
    for (let lng = 110; lng <= maxLon; lng += interval) {
      if (lng >= minLon) {
        const newLng = roundNum(lng, roundDecimal);
        graticule.push({
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: [
              [newLng, -90],
              [newLng, 90],
            ],
          },
          properties: { value: newLng },
        });
      }
    }
    for (let lat = 5; lat <= maxLat; lat += interval) {
      if (lat >= minLat) {
        const newLat = roundNum(lat, roundDecimal);
        graticule.push({
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: [
              [-180, newLat],
              [180, newLat],
            ],
          },
          properties: { value: newLat },
        });
      }
    }
    map.getSource("graticule").setData({
      type: "FeatureCollection",
      features: graticule,
    });
    // 経度線上の表示情報の更新
    map.getSource("lon-points").setData({
      type: "FeatureCollection",
      features: pointLonFeatures,
    });
    // 緯度線上の表示情報の更新
    map.getSource("lat-points").setData({
      type: "FeatureCollection",
      features: pointLatFeatures,
    });
  }

  // ここから地図追加部分スタート
  if (!map.getSource("graticule")) {
    map.addSource("graticule", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });
    map.addSource("lon-points", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });
    map.addSource("lat-points", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });
    let initialSet = false;
    if (!initialSet) {
      updateLonPoints();
      initialSet = true;
    }

    map.addLayer({
      id: "graticule",
      type: "line",
      source: "graticule",
      paint: {
        "line-color": "blue",
        "line-opacity": 1.0,
      },
      layout: {
        visibility: "visible",
      },
    });
    map.addLayer({
      id: "lon-points",
      type: "symbol",
      source: "lon-points",
      layout: {
        "text-field": ["get", "description"],
        "text-size": 15,
        "text-font": ["Arial Unicode MS Bold"],
        visibility: "visible",
      },
      paint: {
        "text-color": "red",
        "text-halo-color": "white",
        "text-halo-width": 2,
      },
    });
    map.addLayer({
      id: "lat-points",
      type: "symbol",
      source: "lat-points",
      layout: {
        "text-field": ["get", "description"],
        "text-size": 15,
        "text-font": ["Arial Unicode MS Bold"],
        visibility: "visible",
      },
      paint: {
        "text-color": "blue",
        "text-halo-color": "white",
        "text-halo-width": 2,
      },
    });
    map.on("moveend", updateLonPoints);
  }
}
