import * as symbol from "../control/draw_control/symbol";

/**
 主に作図データを表現するためのスタイル
 */
export default [
  {
    // point(アイコン)
    id: "idismap-custom-point",
    type: "symbol",
    filter: [
      "all",
      ["==", ["get", symbol.keyInStyle], symbol.value.CUSTOM_STYLE],
      ["==", ["get", "user__markerType"], "Icon"],
    ],
    layout: {
      "text-size":[
        "case",
        ["has", "user_isStickyNote"],
        ["get", "user__fontSize"],
        0
      ],
      "text-pitch-alignment":"viewport",
      "text-rotation-alignment":"viewport",
      "text-field":["get", "user__comment"],
      "text-font": ["Arial Unicode MS Bold"],
      "text-anchor":"top-left",
      "text-keep-upright":true,
      "text-allow-overlap":true,
      "text-max-width":100,
      "icon-allow-overlap": true,
      "icon-image": [
        "case",
        ["has", "user__stickyNoteThumbnail"],
        ["get", "user__stickyNoteThumbnail"],
        ["has", "user__iconUrl"],
        ["get", "user__iconUrl"],
        symbol.defaultIcon,
      ],
      "icon-size": [
        "case",
        ["has", "user__stickyNoteThumbnail"],
        0.2,
        ["has", "user_isStickyNote"],
        0,
        ["has", "user__iconSize"],
        ["get", "user__iconSize"],
        1.0
      ]
    },
    paint: {
      "text-color": [
        "case",
        ["has", "user__fontColor"],
        ["get", "user__fontColor"],
        "black"
      ],
      "text-halo-color": [
        "case",
        ["has", "user__borderColor"],
        ["get", "user__borderColor"],
        "black"
      ],
      "text-halo-width": [
        "case",
        ["has", "user__borderWidth"],
        ["get", "user__borderWidth"],
        10
      ]
    },
  },
  {
    // 普通のline
    id: "idismap-custom-polyline",
    type: "line",
    filter: [
      "all",
      ["==", ["get", symbol.keyInStyle], symbol.value.CUSTOM_STYLE],
      ["==", ["get", "user__markerType"], "polyline"],
    ],
    paint: {
      "line-color": [
        "case",
        ["has", "user__color"],
        ["get", "user__color"],
        "black",
      ],
      "line-width": [
        "case",
        ["has", "user__weight"],
        ["get", "user__weight"],
        3,
      ],
      "line-opacity": [
        "case",
        ["has", "user__opacity"],
        ["get", "user__opacity"],
        1,
      ],
    },
  },
  {
    // 点線
    id: "idismap-custom-dash-line",
    type: "line",
    filter: [
      "all",
      ["==", ["get", symbol.keyInStyle], symbol.value.CUSTOM_STYLE],
      ["==", ["get", "user__markerType"], "dashline"],
    ],
    paint: {
      "line-color": [
        "case",
        ["has", "user__color"],
        ["get", "user__color"],
        "black",
      ],
      "line-width": [
        "case",
        ["has", "user__weight"],
        ["get", "user__weight"],
        3,
      ],
      "line-opacity": [
        "case",
        ["has", "user__opacity"],
        ["get", "user__opacity"],
        1,
      ],
      "line-dasharray": [2, 1], // 現状カスタム不可能
    },
  },
  {
    // 鎖線
    id: "idismap-custom-chain-line",
    type: "line",
    filter: [
      "all",
      ["==", ["get", symbol.keyInStyle], symbol.value.CUSTOM_STYLE],
      ["==", ["get", "user__markerType"], "chainline"],
    ],
    paint: {
      "line-color": [
        "case",
        ["has", "user__color"],
        ["get", "user__color"],
        "black",
      ],
      "line-width": [
        "case",
        ["has", "user__weight"],
        ["get", "user__weight"],
        3,
      ],
      "line-opacity": [
        "case",
        ["has", "user__opacity"],
        ["get", "user__opacity"],
        1,
      ],
      "line-dasharray": [2, 1, 0.5, 1],
    },
  },
  {
    // 鉄道線の基部
    id: "idismap-custom-train-line-base",
    type: "line",
    filter: [
      "all",
      ["==", ["get", symbol.keyInStyle], symbol.value.CUSTOM_STYLE],
      ["==", ["get", "user__markerType"], "trainline"],
    ],
    paint: {
      "line-color": [
        "case",
        ["has", "user__color"],
        ["get", "user__color"],
        "black",
      ],
      "line-width": [
        "case",
        ["has", "user__weight"],
        ["get", "user__weight"],
        3,
      ],
      "line-opacity": [
        "case",
        ["has", "user__opacity"],
        ["get", "user__opacity"],
        1,
      ],
    },
  },
  {
    // 鉄道線の上部(白点線)
    id: "idismap-custom-train-line-white",
    type: "line",
    filter: [
      "all",
      ["==", ["get", symbol.keyInStyle], symbol.value.CUSTOM_STYLE],
      ["==", ["get", "user__markerType"], "trainline"],
    ],
    paint: {
      "line-color": "white",
      "line-width": [
        "case",
        ["has", "user__weight"],
        ["*", ["get", "user__weight"], 0.66],
        2,
      ],
      "line-opacity": [
        "case",
        ["has", "user__opacity"],
        ["get", "user__opacity"],
        1,
      ],
      "line-dasharray": [2, 2],
    },
  },
  {
    // polygonの枠線
    id: "idismap-custom-polygon-outline",
    type: "line",
    filter: [
      "all",
      ["==", ["get", symbol.keyInStyle], symbol.value.CUSTOM_STYLE],
      ["==", ["get", "user__markerType"], "polygon"],
    ],
    paint: {
      "line-color": [
        "case",
        ["has", "user__color"],
        ["get", "user__color"],
        "black",
      ],
      "line-width": [
        "case",
        ["has", "user__weight"],
        ["get", "user__weight"],
        3,
      ],
      "line-opacity": [
        "case",
        ["has", "user__opacity"],
        ["get", "user__opacity"],
        1,
      ],
    },
  },
  {
    // polygonの中身
    id: "idismap-custom-polygon-fill",
    type: "fill",
    filter: [
      "all",
      ["==", ["get", symbol.keyInStyle], symbol.value.CUSTOM_STYLE],
      ["==", ["get", "user__markerType"], "polygon"],
    ],
    paint: {
      "fill-color": [
        "case",
        ["has", "user__fillColor"],
        ["get", "user__fillColor"],
        "black",
      ],
      "fill-opacity": [
        "case",
        ["has", "user__fillOpacity"],
        ["get", "user__fillOpacity"],
        1,
      ],
    },
  },
];
