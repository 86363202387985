/**
 * 観測情報表示用レイヤー
 * 内容がrefreshできるGeoJSONレイヤーを基礎とする
 * TODO: 基礎部分を分離して 'DynamicGeoJSONLayer' などを作る
 * - ベースとなる地物とそれぞれの状況を表した情報を組み合わせる
 * - 状況を表す情報を入れ替えることができる
 * - 情報の入れ替えは、URLが変更されたことを起点とする
 * @module app/observation/map/ObservationLayer
 */

import { GeoJsonLayer } from "idismap";

define([
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/promise/all',
    'dojo/topic',
    'dojox/lang/functional',
    'idis/service/Requester',
    'idis/consts/QUERY',
    'idis/control/Locator',
    'app/model/ObservatoryManager',
    'app/observation/LatestData'
], function (array, declare, lang, Deferred, all, topic, df,
    Requester, QUERY, Locator, ObservatoryManager, LatestData) {

    return declare(null, {
        /** @type {import("idismap").GeoJsonLayer} */
        layer: null,

        /** @type {number} layerId */
        layerId: null,

        /** @type {object} styleJs */
        styleJs: {},

        /** @type {string} 現在の最新の時刻が書いてあるjsonへのURL */
        statusUrl: null,

        /** @type {string} レイヤーの地物種類(Point/Polygon/etc.) */
        geometryType: null,

        /** @type {RequestMemory} 観測局マスターストア */
        store: null,

        /** @type {object} 今表示している時点でのURL状態, URL更新時の比較用 */
        _lastQuery: {},

        /** @type {string} 観測データの最新時刻を保管 */
        _latestDataTimestamp: null,  // 観測データの最新時刻を保管

        _handle: null, // Locatorを監視するハンドラ
        _observatories: {}, // 観測局一覧

        /**
         *
         * @param {object} layerInfo
         * @param {object} styleJs style.jsの内容
         */
        constructor: function (layerInfo, styleJs) {
            this.store = layerInfo.dynamic.store;
            this.statusUrl = layerInfo.dynamic.statusUrl;
            this.geometryType = layerInfo.dynamic.type;
            this.layerId = layerInfo.id;
            this.layerOption = this.generateLayerOption(layerInfo, styleJs);
        },

        /**
         * layerInfoの情報からレイヤーオプションを作る
         *
         * minZoom/maxZoomが入っていたらそれを入れる.
         *
         * 将来 Idismap.GeoJsonLayer.GeoJsonLayerParameterのプロパティが増えて,
         * そのプロパティがlayerInfoの情報を使うときはここに足す.
         * @param {object} layerInfo
         * @param {object} styleJs
         * @returns レイヤーオプションのオブジェクト
         */
        generateLayerOption: function(layerInfo, styleJs) {
            var result = {
                styleJs: styleJs,
            };

            // layerInfoに入っているオプションを適用
            ['minZoom', 'maxZoom'].forEach(function(prop) {
                if (layerInfo[prop]) {
                    result[prop] = layerInfo[prop];
                }
            });

            return result;
        },

        /**
         * レイヤー生成
         *
         * @returns {promise} レイヤーが生成されたらresolveされるもの
         */
        createLayer: function () {
            var latestData = new LatestData({ url: this.statusUrl + '/../' + 'data.json' });
            var promise = new Deferred();

            all({
                // マスター情報を生成
                master: this.generateMaster(),
                // データの最新更新情報を取得
                latest: latestData.load()

            }).then(
                lang.hitch(this, function () {
                    // クエリーに時間指定があったらその時間のものを表示
                    this._lastQuery = Locator.getQuery();
                    if (this._lastQuery.datetime) {
                        this._latestDataTimestamp = this._lastQuery.datetime;

                        // クエリーに時間指定がなかったら最新を表示
                    } else {
                        var timestamp = latestData.getLatestDateTimestamp();
                        // YYYY-mm-DD HH:MM:ss -> YYYY-mm-DD-HH-MM
                        this._latestDataTimestamp =
                            timestamp.replace(/(\d{4}-\d{2}-\d{2}) (\d{2}):(\d{2}):\d{2}/, '$1-$2-$3');
                    }

                    // 初期表示
                    this._refresh(this._latestDataTimestamp, this._lastQuery.mode || '10').then(
                        lang.hitch(this, function(geojson) {
                            this.layer = new GeoJsonLayer(this.layerId,
                                lang.mixin(this.layerOption, {
                                    geojson: geojson,
                                })
                            );

                            // URLの変更を監視
                            var handle = Locator.on('change', lang.hitch(this, this.onLocationChanged));

                            // このレイヤーが削除された時に動かすfunctionを登録
                            this.layer.addRemoveEventHandler(function () {
                                handle.remove();
                                // 時刻表示を消してもらう
                                topic.publish('app/map/TimestampsPanel::disappear');
                            });

                            promise.resolve();
                        })
                        , function(error) {
                            promise.reject(error);
                        }
                    );
                }),
                function (error) {
                    promise.reject(error);
                }
            );

            return promise;
        },

        // 観測所マスターデータを読み込む
        // 更新処理高速化のため、配列形式からHash形式に変換しておく
        generateMaster: function () {
            return this.store.fetch().then(lang.hitch(this, function (items) {
                items.forEach(lang.hitch(this, function (item) {
                    // DB上、observatoryIdは数値でマスターデータも数値で入っている
                    // プロパティ名に数値は不可なので、文字列にして格納する
                    // 数値を文字列に変換（+''がパフォーマンス一番良いらしい）
                    this._observatories[item.observatoryId + ''] = item;
                }));
            }));
        },

        // URL変更時の処理
        // 時刻が変わったら観測情報を取得してレイヤーを更新
        onLocationChanged: function () {
            // 現在のクエリー情報を取得
            var query = Locator.getQuery();

            // 緯度経度、レイヤー、ズームレベルが変更された場合はデータの更新はないので何もしない
            // 最新日時が選択されている状態で「最新」ボタンをクリックすると、日時は変わっていないがデータが
            // 更新されている可能性があるので、日付の一致は確認しない
            if (query[QUERY.LATLNG] !== this._lastQuery[QUERY.LATLNG]) {
                this._lastQuery = query;
                return;
            }
            if (query[QUERY.LAYER_LIST] !== this._lastQuery[QUERY.LAYER_LIST]) {
                this._lastQuery = query;
                return;
            }
            if (query[QUERY.ZOOM] !== this._lastQuery[QUERY.ZOOM]) {
                this._lastQuery = query;
                return;
            }

            // 日時や時間モードが指定されてなかった時のデフォルト値
            var timeMode = query.mode ? query.mode : '10';
            var datetime = query.datetime ? query.datetime : this._latestDataTimestamp;
            // レイヤーを更新
            this._refresh(datetime, timeMode).then(
                lang.hitch(this, function(geojson) {
                    this.layer.updateData(geojson);
                }),
                function(error) {
                    throw new Error(error);
                }
            );

            // 次の更新に備えてクエリー状態を保存
            this._lastQuery = query;
        },

        // レイヤー内容を指定された時間の観測情報で更新
        _refresh: function (timestamp, timeMode) {
            // datetimeで表されるjsonファイルを取得
            var url = this.statusUrl + '/' + timestamp + '.json';
            return Requester.get(url).then(
                lang.hitch(this, function (json) {
                    this._latestDataTimestamp = timestamp;

                    // jsonからgeojsonを作る
                    var geojson = this._generateGeoJson(json, timeMode);

                    // トップページに表示レイヤーの時刻表示用のpub
                    topic.publish('app/map/TimestampsPanel::show', {
                        timestamp: timestamp,
                        layerName: this.statusUrl.split('/').slice(-2)[0]
                    });

                    return geojson;

                }),
                function (error) {
                    throw new Error(error);
                }
            );
        },

        // 取得した観測データから表示させるためのGeoJSONを生成
        _generateGeoJson: function (json, mode) {

            // 空のgeojsonからスタート
            var geojson = this._emptyGeoJson();

            // statusに緯度経度と各種プロパティが並んで入っているので、GeoJSON形式に変換
            array.forEach(json.items, lang.hitch(this, function (item, i) {
                // マスター情報を取得
                // DB上、observatoryIdは数値でマスターデータも数値で入っている
                // プロパティ名に数値は不可なので、文字列にして格納する
                // 数値を文字列に変換（+''がパフォーマンス一番良いらしい）
                var observatory = this._observatories[item.observatoryId + ''];

                if (!observatory) { return; }

                var feature = {
                    geometry: {
                        type: this.geometryType,
                        // マスターから緯度経度を取得
                        'coordinates': [observatory.longitude, observatory.latitude]
                    },
                    type: 'Feature',
                    id: i + 1,
                    properties: {}
                };

                // すべてのプロパティをfeature.propertiesに入れる
                df.forIn(item, function (value, key) {
                    feature.properties[key] = value;
                });

                // 時間モードをfeature.propertiesに入れる
                feature.properties.mode = mode;

                // 観測所名に管理区分に応じたサフィックスをつけて再セット
                var suffix =
                    (!!ObservatoryManager[observatory.managerCd]) ?
                        ObservatoryManager[observatory.managerCd].suffix : '';
                feature.properties.name = item.name + suffix;

                geojson.features.push(feature);
            }));

            return geojson;
        },

        // 空のgeojsonを作る
        _emptyGeoJson: function () {
            return {
                type: 'FeatureCollection',
                features: []
            };
        }
    });
});