import { sourceName as sourceNameRule } from "../common/name";
import * as GeoJsonUtils from "./geojson_utils";
import VectorLayer from "./vector_layer";

/**
 * @typedef GeoJsonLayerParameter
 * @property {object} geojson 地物を表すGeoJson
 * @property {object} status 状態を表すjson
 * @property {number} minZoom レイヤーを表示する最小のズームレベル. これ以上地図を引くと表示が消える
 * @property {number} maxZoom レイヤーを表示する最大のズームレベル. これ以上地図をよると表示が消える
 * @property {StyleJs} styleJs 表示をカスタマイズするためのstyle
 */

/**
 * @typedef StyleJs
 * @property {string[]} iconUrls 使用するアイコンURL
 * @property {FillPattern[]} fillPatterns 塗りつぶしに適用するsvg定義
 * @property {string} statusKey 状態を表現したデータのプロパティ名 or プロパティを抽出するfunction. GeoJsonLayerParameter.statusから取り出すためのキー
 * @property {object} style アイコンの定義などを指定するスタイル, Maplibreのスタイルフォーマットに準拠 ref. https://maplibre.org/maplibre-style-spec/layers/
 * @property {object[]} styles styleのArray
 * @property {(feature: object, status: object) => boolean} match geojsonの地物と状態を表すオブジェクトが一致するものかを判定するfunction
 * @property {(feature: object, status: object|undefined) => boolean} filter geojsonの地物を表示するかどうかを判定するfunction
 * @property {(properties: object) => string} popup 地物をクリックしたときに表示するポップアップの中身を作るfunction
 */

/**
 * geojsonを地物表現に使うレイヤー操作を行うクラス
 *
 * - レイヤー生成
 * - 地図に載せる, 外す
 * - その他操作
 *
 * を行う.
 * レイヤー1つにつき, maplibreのsource1つ, maplibreのlayer複数が存在する.
 *
 */
export default class GeoJsonLayer extends VectorLayer {
  /** @type{GeoJsonLayerParameter} */
  parameter;

  /** @type{object} 地図に表示するGeoJSONデータ */
  sourceData;

  /** @type{string|number} 利用元から渡されるレイヤーID */
  layerId;

  /**
   * constructor
   *
   * @param {String|Number} layerId レイヤーID
   * @param {GeoJsonLayerParameter} parameter
   */
  constructor(layerId, parameter) {
    super(layerId, parameter);

    this.layerId = layerId;
    this.parameter = parameter;
    this.sourceData = this.manipulateSource(parameter.geojson);
  }

  /**
   * 表示している地物情報を更新
   *
   * filter/matchを通して状態をmergeしてデータを更新する
   *
   * @param {object} geojson 新しく表示したいgeojson
   */
  updateData(geojson) {
    this.sourceData = this.manipulateSource(geojson);
    this.addSource(this.map);
  }

  /**
   * 地物の情報を更新
   *
   * 観測情報更新, 気象注意報更新など, geojsonの地物を表す状態を更新するときに呼ぶ
   * @param {object} status 新しく適用したいstatus
   */
  updateStatus(status) {
    this.parameter.status = status;
    this.sourceData = this.manipulateSource(this.sourceData);
    this.addSource(this.map);
  }

  /**
   * geojsonの地物を追加する
   *
   * geojson全体を更新できなくて, 1つだけ足したいときに使う
   * - 地震レイヤーに震源のfeatureを追加
   * @param {object} feature 追加するfeature
   */
  addFeature(feature) {
    this.sourceData.features.push(feature);
    this.sourceData = this.manipulateSource(this.sourceData);
    this.addSource(this.map);
  }

  /**
   * 地図に載せる地物を操作
   *
   * 与えられたgeojsonに対して, 他のjsonとマージしたり, 表示対象外のfeatureを取り除く操作を行う.
   *
   * @param {object} geojson 操作対象となるgeojson
   * @returns 操作した結果のgeojson
   */
  manipulateSource(geojson) {
    const mergedGeoJson = geojson;

    const { styleJs } = this.parameter;

    // parameter.statusに"地物の状態"が入っている. これを配列の形式で取り出す
    // 例: 市町村のpolygonに対して"気象警報"の状態が入っている
    let statusArray;
    if (typeof styleJs.statusKey === "function") {
      statusArray = styleJs.statusKey(this.parameter.status);
    } else if (styleJs.statusKey) {
      statusArray = this.parameter.status[styleJs.statusKey];
    }

    // 表示するべき地物をfilterする
    if (styleJs.filter) {
      mergedGeoJson.features = GeoJsonUtils.filterAndMerge(
        mergedGeoJson.features,
        statusArray,
        styleJs.filter,
      );
    }

    if (statusArray && styleJs.match) {
      mergedGeoJson.features = GeoJsonUtils.mergeStatus(
        mergedGeoJson.features,
        statusArray,
        styleJs.match,
      );
    }

    return mergedGeoJson;
  }

  /**
   * 地図にデータ(source)を登録
   *
   * @param {maplibregl.Map} map 登録する先のmap
   */
  addSource(map) {
    // geojsonをsourceに追加
    this.sourceName = sourceNameRule(this.layerId);
    const source = map.getSource(this.sourceName);
    if (!source) {
      map.addSource(this.sourceName, {
        type: "geojson",
        data: this.sourceData,
      });
    } else {
      source.setData(this.sourceData);
    }
  }
}
