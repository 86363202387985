/**
 * mapbox-gl-drawを利用する複数のコントロールに対して,描画イベントを振り分ける機能
 *
 * mapbox-gl-drawは1アプリケーションに対して1つだけロードされるもので,複数のコントロールそれぞれがロードするとSourceが競合する.
 * そのため,idismap全体で1つのmapbox-gl-drawをロードする.
 * ここで問題になるのは,"draw.create"など描画関連のイベントを複数コントロールが監視していると,
 * Aコントローラーが作図したオブジェクトに対してBコントローラーが処理を行ってしまい都合が悪い.
 *
 * この機能は,"draw.create"などのイベントを一元的に受け取り,"現在Active"であるコントロールに振り分ける役割を持つ.
 * そのため、以下を行う
 * - 現在Activeなmapbox-gl-drawを利用するコントロールの管理
 * - Activeなコントロールに対してイベントを渡す
 *   - イベントを渡すのは,map.fireではなくfunction呼び出しとする
 */
export default class DrawEventDispatcher {
  /** @type {maplibregl.Map} */
  map;

  /** @type {MapboxDraw} */
  mapboxDraw;

  /** @type {object[]} 登録されているコントロール群 */
  controls = {};

  /** @type {string} 現在Activeなコントロール名 */
  activeControlName = null;

  /**
   * constructor
   *
   * @param {maplibregl.Map} map
   * @param {MapboxDraw} mapboxDraw
   */
  constructor(map, mapboxDraw) {
    this.map = map;
    this.mapboxDraw = mapboxDraw;
    this.registerEventHandlers();
  }

  /**
   * コントロールを登録
   *
   * @param {object} control コントロール本体
   * @param {string} name コントロールの名前
   */
  addControl(control, name) {
    if (this.controls[name]) {
      throw new Error(`${name}はすでに登録されている.`);
    }

    this.controls[name] = control;
  }

  /**
   * イベントハンドラー
   */
  registerEventHandlers() {
    // コントロールが有効になったことの通知
    this.map.on("change-control", (e) => {
      // 送ってきたコントロールが今Activeなものだったらcallbackだけ呼び出しておしまい
      if (this.activeControlName === e.controlName) {
        if (e.callback) {
          e.callback();
        }

        // 作図レイヤー以外のマウスイベントは抑止する
        this.map.fire("start_drawing");

        return;
      }

      // それまでActiveだったコントロールに後始末をさせる
      if (this.activeControlName) {
        this.controls[this.activeControlName].onDisable();
      }

      if (e.controlName && this.controls[e.controlName]) {
        // これからActiveになるコントロールがイベントに入っていれば
        // それを有効化する
        this.activeControlName = e.controlName;

        if (e.callback) {
          e.callback();
        }

        // 作図を開始したら他のレイヤーに対するマウスイベント処理を停止する
        // "start_drawing"は他のレイヤーが受け取ってマウスイベント処理を停止する
        this.map.fire("start_drawing");
      } else {
        // イベントが空の時は,作図を使わないコントロールが有効になったということ
        // その時はactiveControlNameを空にしておく
        this.activeControlName = null;

        // 作図を止めたら他のレイヤーに対するマウスイベントが動くようにする
        // "end_drawing"は他のレイヤーが受け取ってマウスイベント処理を再開する
        this.map.fire("end_drawing");
      }
    });

    this.map.on("draw.create", (e) => {
      this.dispatchEvent("draw.create", e);
    });

    this.map.on("draw.modechange", (e) => {
      this.dispatchEvent("draw.modechange", e);
    });

    this.map.on("draw.selectionchange", (e) => {
      this.dispatchEvent("draw.selectionchange", e);
    });

    // キーボード入力
    this.map.getContainer().addEventListener("keydown", (e) => {
      this.dispatchEvent("keydown", e);
    });
  }

  /**
   * イベントをfunction呼び出しにしてコントロール処理を呼び出す
   *
   * @param {string} eventName
   * @param {object} event
   */
  dispatchEvent(eventName, event) {
    const control = this.controls[this.activeControlName];

    if (!control) {
      this.#cancel(event);
      throw new Error(
        "現在Activeなコントロールが存在しないので'draw.create'イベントを処理できない",
      );
    }

    // "draw:create" => "onDrawCreate"
    // 難解すぎるのでなんとかしたいのだが
    const functionName = `on${eventName
      .split(".")
      .map((token) => {
        if (!token) {
          return "";
        }
        const head = token[0];
        const tail = token.substring(1);
        return `${head.toUpperCase()}${tail.toLowerCase()}`;
      })
      .join("")}`;

    if (!control[functionName]) {
      // 動作確認用
      // functionが存在しなかったら何もしない, が正しいので最終的にはこのブロック不要
      console.error(
        `コントロール${this.activeControlName}に${functionName}が存在しない`,
      );
      return;
    }

    control[functionName](event);
  }

  /**
   * 作図をキャンセルする処理
   *
   * 処理を受け渡す先がないなど,処理できない場合に描かれている作図をキャンセルしたり
   * カーソルポインタを元に戻したりする
   */
  #cancel(event) {
    if (event.features && event.features.length) {
      this.mapboxDraw.delete(event.features[0].id);
    }
    const canvas = this.map.getCanvas();
    canvas.style.cursor = "grab";
    this.map.fire("end_drawing");
  }
}
