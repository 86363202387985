import { area, length } from "@turf/turf";
import "./measure_control.css";

// 計測結果エリアに表示するデフォルトテキスト
// 空っぽだとspanの高さがなくなってしまう
// spanの高さを固定にするよりも, 何か文字を入れたい.
const defaultResultText = "計測結果";

/**
 * mapに入れるsourceの名前.
 * 背景地図入れ替え時の処理に必要になる
 */
const symbolName = {
  source: "idismap-control-measures-source",
  point: "idismap-control-measure-point",
  line: "idismap-control-measure-line",
  polygon: "idismap-control-measure-polygon",
};

/**
 * 距離・面積計測コントロール
 *
 * maplibre-gl-measuresはいくつか問題があるので新しく作る
 * - 背景地図を切り替えるとラベルが消える
 * - viteのdevServerで動かない
 *
 * 計測部分のコードはオフィシャルのexampleを参考にしている
 *
 * https://maplibre.org/maplibre-gl-js/docs/examples/measure/
 */
export default class MeasureControl {
  // 計測対象とする地図オブジェクト, 'onAdd'で渡される
  map;

  // メインコントロール
  mainContainer;

  // メインコントロールに置くボタン
  controlButton;

  // アクティブ時に表示されるコントロール
  measureResultContainer;

  // 計測結果を表示するエリア
  measureResult;

  // 計測をクリアするためのボタン
  clearButton;

  // 計測を終了するためのボタン
  leaveButton;

  // 計測モード (LINE/POLYGON)
  measureMode;

  // GeoJSON object to hold our measurement features
  geojson = {
    type: "FeatureCollection",
    features: [],
  };

  // 距離計測用の線を表すlinestring
  linestring = {
    type: "Feature",
    geometry: {
      type: "LineString",
      coordinates: [],
    },
  };

  // 面積計測用の面を表すpolygon
  polygon = {
    type: "Feature",
    geometry: {
      type: "Polygon",
      coordinates: [],
    },
  };

  /**
   * 計測コントロール生成, mapから `new MeasureControl()` すると呼ばれる
   * @param {maplibregl.Map} map
   * @returns コントロールとして表示するHTMLElement
   */
  onAdd(map) {
    this.map = map;

    // コントロールを作る
    // 本体
    this.mainContainer = document.createElement("div");
    this.mainContainer.classList.add(
      "maplibregl-ctrl",
      "maplibregl-ctrl-group",
    );

    // 普段(非アクティブ時)に表示するボタン
    this.controlButton = document.createElement("button");
    this.controlButton.title = "計測";
    this.controlButton.setAttribute("aria-label", "計測");
    this.controlButton.classList.add(
      "idismap-measure-button",
      "maplibregl-ctrl-icon",
    );
    this.mainContainer.appendChild(this.controlButton);

    // アクティブ時に表示する領域
    this.measureResultContainer = document.createElement("div");
    this.measureResultContainer.classList.add("idismap-measure-container");

    // - 表示部分
    this.measureResult = document.createElement("span");
    this.measureResult.className = "measure-result";
    this.measureResult.innerText = defaultResultText;

    // - 距離/面積セレクター
    //    inputとlabelのセットが距離計測用,面積計測用の2つある
    const selectorContainer = document.createElement("div");
    selectorContainer.className = "selector-area";

    const distanceInput = document.createElement("input");
    distanceInput.id = "distanceInput";
    distanceInput.type = "radio";
    distanceInput.name = "measuresInput";
    distanceInput.checked = true;
    this.measureMode = "LINE";
    distanceInput.addEventListener("change", this.#onMeasureLine);

    const distanceLabel = document.createElement("label");
    distanceLabel.htmlFor = "distanceInput";
    distanceLabel.innerText = "距離";

    const areaInput = document.createElement("input");
    areaInput.id = "areaInput";
    areaInput.type = "radio";
    areaInput.name = "measuresInput";
    areaInput.addEventListener("change", this.#onMeasurePolygon);

    const areaLabel = document.createElement("label");
    areaLabel.htmlFor = "areaInput";
    areaLabel.innerText = "面積";

    selectorContainer.appendChild(distanceInput);
    selectorContainer.appendChild(distanceLabel);
    selectorContainer.appendChild(areaInput);
    selectorContainer.appendChild(areaLabel);

    const measureButtonContainer = document.createElement("div");
    measureButtonContainer.className = "button-area";
    this.leaveButton = document.createElement("button");
    this.leaveButton.innerText = "終了";

    this.clearButton = document.createElement("button");
    this.clearButton.innerText = "クリア";

    measureButtonContainer.appendChild(this.clearButton);
    measureButtonContainer.appendChild(this.leaveButton);

    // 表示部分と距離/面積セレクターをアクティブ時表示領域に入れる
    this.measureResultContainer.appendChild(this.measureResult);
    this.measureResultContainer.appendChild(selectorContainer);
    this.measureResultContainer.appendChild(measureButtonContainer);

    // アクティブ時表示領域をコントロール本体に入れる
    this.mainContainer.appendChild(this.measureResultContainer);

    // コントロールのボタンを押したらアクティブ時表示領域が出るようにする
    this.controlButton.addEventListener("click", this.#onEnable);

    // 終了ボタンを押したりEscキーを押したらアクティブ状態を終了
    this.leaveButton.addEventListener("click", this.onDisable);
    window.addEventListener("keydown", this.onKeyDown);

    // クリアボタンを押したら地図上に描かれているものをクリアする
    this.clearButton.addEventListener("click", this.#clearMap);

    this.map.on("load", () => {
      this.map.addSource(symbolName.source, {
        type: "geojson",
        data: this.geojson,
      });

      // 押した場所を表す点のレイヤー
      this.map.addLayer({
        id: symbolName.point,
        type: "circle",
        source: symbolName.source,
        paint: {
          "circle-radius": 5,
          "circle-color": "#000",
        },
        filter: ["in", "$type", "Point"],
      });

      // 距離を計測するための線を出すレイヤー
      this.map.addLayer({
        id: symbolName.line,
        type: "line",
        source: symbolName.source,
        layout: {
          "line-cap": "round",
          "line-join": "bevel",
        },
        paint: {
          "line-color": "#000",
          "line-width": 2.5,
        },
        filter: ["in", "$type", "LineString"],
      });

      // 面積を計測するための面を出すレイヤー
      this.map.addLayer({
        id: symbolName.polygon,
        type: "fill",
        source: symbolName.source,
        paint: {
          "fill-color": "#000",
          "fill-opacity": 0.7,
        },
        filter: ["in", "$type", "Polygon"],
      });
    });

    return this.mainContainer;
  }

  /**
   * このコントロールが破棄された時のアクション, ページ遷移などで発生する
   * onAddで作ったDOMやイベントを削除して遷移した後にゴミを残さないようにする
   */
  onRemove() {
    // コントロールのボタンへのイベント削除
    this.controlButton.removeEventListener("click", this.#onEnable);

    // 計測終了ボタン/Escキーのイベント削除
    this.leaveButton.removeEventListener("click", this.onDisable);
    window.removeEventListener("keydown", this.onKeyDown);

    // クリアボタンのイベント削除
    this.clearButton.removeEventListener("click", this.#clearMap);

    // コントローラーのDOM削除
    this.mainContainer.parentNode.removeChild(this.mainContainer);
  }

  /**
   * キー入力に対するアクション
   * 計測が有効な時にEscapeキーが押されると計測を終了させる
   * @param {Event} e
   */
  onKeyDown = (e) => {
    if (
      this.measureResultContainer.style.display === "block" &&
      (e.key === "Escape" || e.key === "Esc")
    ) {
      this.onDisable();
    }
  };

  /**
   * コントロールが有効になった時に発動する
   * 地図に対するアクションを有効にして計測ができるようにする
   */
  #onEnable = () => {
    this.map.fire("change-control", {
      controlName: "DrawControl",
      callback: () => {
        // マウスポインタを'crosshair'にする
        const canvas = this.map.getCanvas();
        canvas.style.cursor = "crosshair";

        this.map.on("click", this.#onMapClick);

        // 計測結果領域を表示
        this.controlButton.style.display = "none";
        this.measureResultContainer.style.display = "block";
      },
    });
  };

  /**
   * コントロールが無効になった時に発動する
   * 地図に対するアクションを消して, 地図デフォルトの動作や他のコントローラーの邪魔をしないようにする
   */
  onDisable = () => {
    this.#clearMap();

    // 地図に対するイベントを外す
    this.map.off("click", this.#onMapClick);

    // 有効な時に表示するエリアを非表示にして, コントロールボタンを表示
    this.measureResultContainer.style.display = "none";
    this.controlButton.style.display = "block";

    // マウスポインタを戻す
    this.map.getCanvas().style.cursor = "grab";
    // 他のレイヤーのマウスイベント捕捉を再開
    this.map.fire("start_drawing");
  };

  /**
   * 地図に計測用に書かれたline/polygonを消す
   */
  #clearMap = () => {
    this.geojson.features = [];
    this.map.getSource(symbolName.source).setData(this.geojson);
    this.measureResult.innerText = defaultResultText;
  };

  #onMeasureLine = () => {
    this.#clearMap();
    this.measureMode = "LINE";
  };

  #onMeasurePolygon = () => {
    this.#clearMap();
    this.measureMode = "POLYGON";
  };

  /**
   * コントロールが有効な時にマップをクリックした時のアクション
   * @param {Event} e
   */
  #onMapClick = (e) => {
    switch (this.measureMode) {
      case "LINE":
        this.#measureLine(e);
        break;

      case "POLYGON":
        this.#measurePolygon(e);
        break;

      default:
        break;
    }
  };

  /**
   * 距離計測
   * @param {Event} e
   */
  #measureLine(e) {
    const features = this.map.queryRenderedFeatures(e.point, {
      layers: [symbolName.point],
    });

    // Remove the linestring from the group
    // So we can redraw it based on the points collection
    if (this.geojson.features.length > 1) this.geojson.features.pop();

    // If a feature was clicked, remove it from the this.map
    if (features.length) {
      const { id } = features[0].properties;
      this.geojson.features = this.geojson.features.filter(
        (point) => point.properties.id !== id,
      );
    } else {
      const point = {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [e.lngLat.lng, e.lngLat.lat],
        },
        properties: {
          id: String(new Date().getTime()),
        },
      };

      this.geojson.features.push(point);
    }

    if (this.geojson.features.length > 1) {
      this.linestring.geometry.coordinates = this.geojson.features.map(
        (point) => point.geometry.coordinates,
      );

      this.geojson.features.push(this.linestring);

      // Populate the distanceContainer with total distance
      this.measureResult.innerText = `距離: ${length(
        this.linestring,
      ).toLocaleString()} km`;
    }

    this.map.getSource(symbolName.source).setData(this.geojson);
  }

  /**
   * 面積計測
   * @param {Event} e
   */
  #measurePolygon(e) {
    const features = this.map.queryRenderedFeatures(e.point, {
      layers: [symbolName.point],
    });

    // Remove the linestring from the group
    // So we can redraw it based on the points collection
    if (this.geojson.features.length > 2) this.geojson.features.pop();

    // If a feature was clicked, remove it from the this.map
    if (features.length) {
      const { id } = features[0].properties;
      this.geojson.features = this.geojson.features.filter(
        (point) => point.properties.id !== id,
      );
    } else {
      const point = {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [e.lngLat.lng, e.lngLat.lat],
        },
        properties: {
          id: String(new Date().getTime()),
        },
      };

      this.geojson.features.push(point);
    }

    if (this.geojson.features.length > 2) {
      const coordinates = this.geojson.features.map(
        (point) => point.geometry.coordinates,
      );

      coordinates.push(coordinates[0]);

      this.polygon.geometry.coordinates = [coordinates];

      this.geojson.features.push(this.polygon);

      const areaMeter = area(this.polygon);
      const flooredArea = Math.round(areaMeter / 1000) / 1000;

      this.measureResult.innerHTML = `面積: ${flooredArea} km<sup>2</sup>`;
    }

    this.map.getSource(symbolName.source).setData(this.geojson);
  }
}
