/**
 * 作図したオブジェクトに付与するプロパティ
 */

const key = "_idismap_draw";
const keyInStyle = "user__idismap_draw";
const value = {
  USE_DEFAULT: "default",
  CUSTOM_STYLE: "confirmed",
};
const defaultIcon = "/images/draw/icon/080.png";
export { key, keyInStyle, value, defaultIcon };
