import MapboxDraw from "@mapbox/mapbox-gl-draw";

const DrawStickyNoteMode = { ...MapboxDraw.modes.draw_point };

DrawStickyNoteMode.onSetup = function() {
  const point = this.newFeature({
    type: MapboxDraw.constants.geojsonTypes.FEATURE,
    properties: {
      isStickyNote: true,
      _comment: ''
    },
    geometry: {
      type: MapboxDraw.constants.geojsonTypes.POINT,
      coordinates: []
    }
  });

  this.addFeature(point);

  this.clearSelectedFeatures();
  this.updateUIClasses({ mouse: MapboxDraw.constants.cursors.ADD });
  this.activateUIButton(MapboxDraw.constants.types.POINT);

  this.setActionableState({
    trash: true
  });

  return { point };
};

export default DrawStickyNoteMode;