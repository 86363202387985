import { layerName, sourceName } from "../common/name";
import BaseLayer from "./base_layer";

/**
 * ラスタータイルを表示するレイヤーの操作を行うクラス
 *
 * - レイヤー生成
 * - 地図に載せる, 外す
 * - その他操作
 *
 * を行う.
 * レイヤー1つにつき, maplibreのsource1つ, maplibreのlayer1つが存在する.
 */
export default class RasterLayer extends BaseLayer {
  /*
   * @typedef RasterLayerParameter
   * @property {string[]} tiles
   * @property {number} minZoom レイヤーを表示する最小のズームレベル. これ以上地図を引くと表示が消える
   * @property {number} maxZoom レイヤーを表示する最大のズームレベル. これ以上地図をよると表示が消える
   * @property {number} overlayZoom タイルが配置されている最大のズームレベル. これ以上地図を寄ると指定ズームレベルのタイルを引き伸ばして表示する
   * @property {number} tileSize 読み込むタイルのサイズ.
   * @property {string} scheme tms or xyz.
   * @property {number} opacity 透過度 (1:透過しない, 0:透過して見えない)
   */

  /** @type{RasterLayerParameter} レイヤーパラメーター, 入っているのはデフォルト値 */
  parameter = {
    tileSize: 256,
    scheme: "tms",
    opacity: 0.7,
  };

  /** @type{import("maplibre-gl").RasterSourceSpecification} */
  sourceSpecification;

  /** @type{string} */
  layerName;

  /**
   * constructor
   *
   * @param {String|Number} layerId レイヤーID
   * @param {RasterLayerParameter} parameter
   */
  constructor(layerId, parameter) {
    super(layerId, parameter);

    this.layerName = `${layerName(layerId)}-raster`;
    this.sourceName = sourceName(layerId);
    Object.assign(this.parameter, parameter);
  }

  /**
   * レイヤーを地図から消す
   */
  removeLayer() {
    this.map.removeLayer(this.layerName);
  }

  /**
   * レイヤーの透過度を変更
   *
   * @param {Number} value 透過度 (1:透過しない, 0:透過して見えない)
   */
  setOpacity(value) {
    this.map.setPaintProperty(this.layerName, "raster-opacity", value);
  }

  /**
   * 表示するタイルのURL変更
   *
   * @param {string} url
   */
  changeTileUrl(url) {
    // layer, sourceを消して
    // 新しいurlでsourceを作り直してlayerを再作成
    // vector tileでは`setUrl(url)`があるのだが, rasterには存在しない.
    this.removeLayer();
    this.map.removeSource(this.sourceName);

    this.sourceSpecification.tiles = [url];
    this.map.addSource(this.sourceName, this.sourceSpecification);
    this.addLayer(this.map);
  }

  /**
   * レイヤーに対するイベントを追加する
   *
   * @type {maplibregl.MapLayerEventType} eventType 'click'など,発動させたいイベントタイプ
   * @param {(maplibregl.MapMouseEvent?) => void} func 発動させる処理が記述されたfunction
   */
  addEvent(eventType, func) {
    this.map.on(eventType, this.layerName, func);
    this.eventHandlers.push({
      type: eventType,
      id: this.layerName,
      func,
    });
  }

  /**
   * レイヤー削除時のイベントを追加する
   * @param {() => void} handlerFunction
   */
  addRemoveEventHandler(handlerFunction) {
    this.removeHandlers.push(handlerFunction);
  }

  /**
   * sourceをmapに追加
   *
   * @param {maplibregl.Map} map
   */
  addSource(map) {
    this.sourceSpecification = {
      type: "raster",
      tiles: this.parameter.tiles,
      tileSize: this.parameter.tileSize,
      scheme: this.parameter.scheme,
      maxzoom:
        this.parameter.overlayZoom ||
        this.parameter.maxZoom ||
        this.map.getMaxZoom(),
    };
    map.addSource(this.sourceName, this.sourceSpecification);
  }

  /**
   * mapにレイヤーを追加 => 地図に表示
   *
   * @param {maplibregl.Map} map
   * @returns {Promise}
   */
  addLayer(map) {
    /** @type {import("maplibre-gl").LayerSpecification} */
    const layerDefinition = {
      id: this.layerName,
      source: this.sourceName,
      minzoom: this.parameter.minZoom || map.getMinZoom(),
      maxzoom: this.parameter.maxZoom || map.getMaxZoom(),
      type: "raster",
      paint: {
        "raster-opacity": this.parameter.opacity || 1,
      },
    };
    map.addLayer(layerDefinition);

    return Promise.resolve();
  }
}
