/**
 * IdisMapのデフォルト値を集めたもの
 */

// 地図
const map = {
  basemapId: "gsi_pale", // 背景地図ID(basemap.jsを参照)
  center: [139.7871, 35.6787], // tokyo
  zoom: 9, // initial zoom
  minZoom: 4,
  hash: true, // URLに座標やズームレベルを入れる.
  localIdeographFontFamily: null, // フォントは全てWebFontを使う. ローカルフォントだと表示が崩れることがある
};

// 地図上のコントロール
const control = {
  placement: "top-left",
};

// 標高/3D表示
const terrain = {
  exaggeration: 1.2,
};

// 地理院のwebfont提供サービスを借用
const webfont = {
  glyphs: "https://maps.gsi.go.jp/xyz/noto-jp/{fontstack}/{range}.pbf",
  fonts: ["NotoSansCJKjp-Regular"],
};

// 地図に出す文字列
const locale = {
  "AttributionControl.ToggleAttribution": "Toggle attribution",
  "AttributionControl.MapFeedback": "Map feedback",
  "FullscreenControl.Enter": "Enter fullscreen",
  "FullscreenControl.Exit": "Exit fullscreen",
  "GeolocateControl.FindMyLocation": "Find my location",
  "GeolocateControl.LocationNotAvailable": "Location not available",
  "LogoControl.Title": "Mapbox logo",
  "NavigationControl.ResetBearing": "地図の向きをリセット",
  "NavigationControl.ZoomIn": "ズームイン",
  "NavigationControl.ZoomOut": "ズームアウト",
  "ScaleControl.Feet": "ft",
  "ScaleControl.Meters": "m",
  "ScaleControl.Kilometers": "km",
  "ScaleControl.Miles": "mi",
  "ScaleControl.NauticalMiles": "nm",
  "TerrainControl.enableTerrain": "3D表示ON",
  "TerrainControl.disableTerrain": "3D表示OFF",
};

const config = {
  map,
  control,
  terrain,
  webfont,
  locale,
};

export default config;
