import { getFontNameFromMap } from "../common/font";
import { layerName, sourceName as sourceNameRule } from "../common/name";
import GeoJsonLayer from "./geojson_layer";

/**
 * @typedef GeoJsonClusterLayerParameter
 * @property {object} geojson 地物を表すGeoJson
 * @property {object} status 状態を表すjson
 * @property {number} minZoom レイヤーを表示する最小のズームレベル. これ以上地図を引くと表示が消える
 * @property {number} maxZoom レイヤーを表示する最大のズームレベル. これ以上地図をよると表示が消える
 * @property {number} clusterMaxZoom クラスター表示する最大のズームレベル. これ以上地図をよるとクラスター表示が消えて全てのポイントが表示される
 * @property {number} clusterMinPoints クラスター表示する最小のポイント数. クラスター対象領域にこの数未満のポイントしかない場合はクラスター表示されずに全てのポイントが表示される
 * @property {number} clusterRadius クラスター表示の大きさ
 * @property {ClusterStyleJs} styleJs 表示をカスタマイズするためのstyle
 */

/**
 * @typedef ClusterStyleJs
 * @property {string[]} iconUrls 使用するアイコンURL
 * @property {FillPattern[]} fillPatterns 塗りつぶしに適用するsvg定義
 * @property {string} statusKey 状態を表現したデータのプロパティ名 or プロパティを抽出するfunction. GeoJsonLayerParameter.statusから取り出すためのキー
 * @property {object} style アイコンの定義などを指定するスタイル, Maplibreのスタイルフォーマットに準拠 ref. https://maplibre.org/maplibre-style-spec/layers/
 * @property {object[]} styles styleのArray
 * @property {object} clusterStyle クラスター表示レイヤーのスタイル
 * @property {string} clusterSymbolText クラスター内にテキスト表示する文字列
 * @property {string} clusterSymbolSize クラスター内にテキスト表示するフォントサイズ
 * @property {(feature: object, status: object) => boolean} match geojsonの地物と状態を表すオブジェクトが一致するものかを判定するfunction
 * @property {(feature: object, status: object|undefined) => boolean} filter geojsonの地物を表示するかどうかを判定するfunction
 * @property {(properties: object) => string} popup 地物をクリックしたときに表示するポップアップの中身を作るfunction
 */

/**
 * map.addSourceで指定するパラメーターを作る
 *
 * @param {GeoJsonClusterLayerParameter} parameter
 * @param {GeoJSON} data
 * @returns {maplibregl.Source}
 */
function generateSourceParameter(parameter, data) {
  /** @type{maplibregl.Source} */
  const result = {
    type: "geojson",
    data,
    cluster: true,
  };

  ["clusterMaxZoom", "clusterMinPoints", "clusterRadius"].forEach((p) => {
    if (parameter[p]) {
      result[p] = parameter[p];
    }
  });

  return result;
}

/** @type {object} クラスター表示レイヤーのデフォルトスタイル */
const defaultClusterStyle = {
  "circle-color": [
    "step",
    ["get", "point_count"], // クラスター内のポイント数で表示内容を変える
    "#51bbd6", // 青
    10, // ポイント数が10までは青
    "#f1f075", // 黄色
    30, // ポイント数が11-30は黄色
    "#f28cb1", // ポイント数が31以上はピンク
  ],
  "circle-radius": [
    "step",
    ["get", "point_count"], // クラスター内のポイント数で表示内容を変える
    20, // 20px
    10, // ポイント数が10までは20px
    30, // 30px
    30, // ポイント数が11-30は30px
    40, // ポイント数が31以上は40px
  ],
};

/**
 * geojsonを地物表現に使うレイヤー操作を行うクラス
 *
 * - レイヤー生成
 * - 地図に載せる, 外す
 * - その他操作
 *
 * を行う.
 * レイヤー1つにつき, maplibreのsource1つ, maplibreのlayer複数が存在する.
 *
 */
export default class GeoJsonClusterLayer extends GeoJsonLayer {
  /** @type{GeoJsonClusterLayerParameter} */
  parameter;

  /**
   * constructor
   *
   * @param {String|Number} layerId レイヤーID
   * @param {GeoJsonClusterLayerParameter} parameter
   */
  constructor(layerId, parameter) {
    super(layerId, parameter);
    this.parameter = parameter;
  }

  /**
   * 地図にデータ(source)を登録
   *
   * @param {maplibregl.Map} map 登録する先のmap
   */
  addSource(map) {
    // geojsonをsourceに追加
    this.sourceName = sourceNameRule(this.layerId);
    const source = map.getSource(this.sourceName);
    if (!source) {
      map.addSource(
        this.sourceName,
        generateSourceParameter(this.parameter, this.sourceData),
      );
    } else {
      source.setData(this.sourceData);
    }
  }

  /**
   * mapにレイヤーを追加 => 地図に表示
   *
   * @param {maplibregl.Map} map
   */
  addLayer(map) {
    // クラスター表示用のレイヤーを追加
    this.addClusterLayer(map);

    return super.addLayer(map);
  }

  /**
   * クラスターを表すレイヤーを地図に追加
   *
   * - クラスターの丸を出すレイヤー
   * - 丸の中に書かれるテキストを出すレイヤー
   * @param {maplibregl.Map} map
   */
  addClusterLayer(map) {
    map.addLayer({
      id: `${layerName(this.layerId)}-cluster`,
      type: "circle",
      source: this.sourceName,
      filter: ["has", "point_count"],
      paint: this.parameter.styleJs.clusterStyle || defaultClusterStyle,
    });

    map.addLayer({
      id: `${layerName(this.layerId)}-cluster-count`,
      type: "symbol",
      source: this.sourceName,
      filter: ["has", "point_count"],
      layout: {
        "text-field":
          this.parameter.styleJs.clusterSymbolText ||
          "{point_count_abbreviated}件",
        "text-font": [`${getFontNameFromMap(map)}`],
        "text-size": this.parameter.styleJs.clusterSymbolSize || 12,
      },
    });

    // クラスターをクリックしたらそのクラスターを中心にしてズームイン
    this.addEvent("click", `${layerName(this.layerId)}-cluster`, (e) => {
      if (this.isDrawing) {
        return;
      }

      const features = map.queryRenderedFeatures(e.point, {
        layers: [`${layerName(this.layerId)}-cluster`],
      });
      const clusterId = features[0].properties.cluster_id;
      map
        .getSource(this.sourceName)
        .getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return;

          map.easeTo({
            center: features[0].geometry.coordinates,
            zoom,
          });
        });
    });

    // レイヤー内のfeaturesにマウスカーソルが入ったらポインタを切り替える
    this.addEvent("mouseenter", `${layerName(this.layerId)}-cluster`, (e) => {
      if (this.isDrawing) {
        return;
      }

      const canvas = map.getCanvas();
      canvas.style.cursor = "pointer";
      e.preventDefault();
    });

    this.addEvent("mouseleave", `${layerName(this.layerId)}-cluster`, (e) => {
      if (this.isDrawing) {
        return;
      }

      const canvas = map.getCanvas();
      canvas.style.cursor = "grab";
      e.preventDefault();
    });
  }
}
