export default function controlLayerVisibility(layerId, visibilityStatus) {
  const { map } = this;
  // layerが既に存在するときだけ実行
  if (map.getLayer(layerId)) {
    const visibility = map.getLayoutProperty(layerId, "visibility");
    if (layerId === "graticule" && map.getLayer(layerId)) {
      // 緯度経度グリッドレイヤー
      if (visibility) {
        map.setLayoutProperty("graticule", "visibility", visibilityStatus);
        map.setLayoutProperty("lon-points", "visibility", visibilityStatus);
        map.setLayoutProperty("lat-points", "visibility", visibilityStatus);
      }
    } else if (layerId === "utmLine" && map.getLayer(layerId)) {
      // UTMグリッドレイヤー
      if (visibility) {
        map.setLayoutProperty("utmLine", "visibility", visibilityStatus);
        map.setLayoutProperty("utmPoints", "visibility", visibilityStatus);
        map.setLayoutProperty("zoneLine", "visibility", visibilityStatus);
        map.setLayoutProperty("zonePoints", "visibility", visibilityStatus);
      }
    } else if (map.getLayer(layerId)) {
      // その他のレイヤー
      if (visibility) {
        map.setLayoutProperty(layerId, "visibility", visibilityStatus);
      }
    }
  }
}
