// import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { loadIcon } from "../../layer/geojson_utils";
import "./draw_control.css";
import * as symbol from "./symbol";

// mapbox-gl-drawをMapLibreで使うおまじない
// ref. https://github.com/maplibre/maplibre-gl-js/issues/2601
// MapboxGlDraw.constants.classes.CONTROL_BASE = "maplibregl-ctrl";
// MapboxGlDraw.constants.classes.CONTROL_PREFIX = "maplibregl-ctrl-";
// MapboxGlDraw.constants.classes.CONTROL_GROUP = "maplibregl-ctrl-group";

function createButton(title, classes) {
  const button = document.createElement("button");
  button.title = title;
  button.setAttribute("aria-label", title);

  if (Array.isArray(classes)) {
    button.classList.add("maplibregl-ctrl-icon", ...classes);
  } else {
    button.classList.add("maplibregl-ctrl-icon", classes);
  }

  return button;
}

/**
 * 作図コントロール
 *
 * mapbox-gl-jsをベースに必要となる追加機能や設定変更を行なっているもの
 */
export default class DrawControl {
  /** @type {maplibregl.Map} 計測対象とする地図オブジェクト, 'onAdd'で渡される */
  map;

  /** @type {MapboxDraw} mapbox-gl-jsのオブジェクト, これに対して描画を指示する */
  mapboxDraw;

  /** @type {HTMLElement} UI Elements */
  rootContainer;

  // DOMにattachしたeventをまとめたlist
  onRemoveActionList = [];

  historyDraw = [];

  /** @type {function(): void} 作図イベントで発動させるフック(callback) */
  createEventHook = null;

  /** @type {function(MapboxDraw.DrawModeChangeEvent): void} 作図イベントで発動させるフック(callback) */
  modeEventHook = null;

  /**
   * constructor
   *
   * @param {MapboxDraw} mapboxDraw
   */
  constructor(mapboxDraw) {
    this.mapboxDraw = mapboxDraw;
  }

  /**
   * 計測コントロール生成, mapから `new DrawControl()` すると呼ばれる
   * @param {maplibregl.Map} map
   * @returns コントロールとして表示するHTMLElement
   */
  onAdd(map) {
    this.map = map;
    this.#createUI();

    // アイコン作図で使うデフォルトアイコンをロードしておく
    map.on("load", () => {
      loadIcon(map, symbol.defaultIcon);
    });

    return this.rootContainer;
  }

  /**
   * 計測コントロール破棄, 画面遷移などで地図が不要になったときに呼ばれる
   */
  onRemove() {
    this.onRemoveActionList.forEach((element) => {
      element();
    });

    // コントローラーのDOM削除
    this.rootContainer.parentNode.removeChild(this.rootContainer);
  }

  /**
   * コントローラーのボタンなどを作るところ
   */
  #createUI() {
    this.rootContainer = document.createElement("div");
    this.rootContainer.classList.add(
      "maplibregl-ctrl",
      "maplibregl-ctrl-group",
    );

    const controlButton = createButton("作図", "idismap-draw-button");
    this.rootContainer.appendChild(controlButton);

    // アクティブ時に表示する領域
    const toolBoxContainer = document.createElement("div");
    toolBoxContainer.classList.add("idismap-draw-container");
    this.rootContainer.appendChild(toolBoxContainer);

    const drawLineButton = createButton("線を描く", "draw-line-button");
    toolBoxContainer.appendChild(drawLineButton);

    const drawPolygonButton = createButton("面を描く", "draw-polygon-button");
    toolBoxContainer.appendChild(drawPolygonButton);

    const controlButtonAction = () => {
      controlButton.style.display = "none";
      toolBoxContainer.style.display = "block";
    };

    controlButton.addEventListener("click", controlButtonAction);
    this.onRemoveActionList.push(() => {
      controlButton.removeEventListener("click", controlButtonAction);
    });

    const onLineButtonClick = () => {
      this.drawLine(null, null);
    };
    drawLineButton.addEventListener("click", onLineButtonClick);
    this.onRemoveActionList.push(() => {
      drawLineButton.removeEventListener("click", onLineButtonClick);
    });

    const onPolygonButtonClick = () => {
      this.drawPolygon(null, null);
    };
    drawPolygonButton.addEventListener("click", onPolygonButtonClick);
    this.onRemoveActionList.push(() => {
      drawPolygonButton.removeEventListener("click", onPolygonButtonClick);
    });
  }

  /**
   * 最後に作図したfeatureを取り出す
   *
   * 最後に作図したものがまだ途中で,削除すべきかを判断するときなどに使う
   */
  #getLastUnConfirmedFeature() {
    const result = this.mapboxDraw.getAll();
    const lastFeature = result.features[result.features.length - 1];

    if (lastFeature && !lastFeature.properties[symbol.key]) {
      return lastFeature;
    }

    return null;
  }

  /**
   * 1つの描画が終わった時
   *
   * ref. https://github.com/mapbox/mapbox-gl-draw/blob/main/docs/API.md#drawcreate
   * @param {MapboxDraw.DrawCreateEvent} e
   */
  onDrawCreate(e) {
    // hookが登録されてたら呼び出し
    if (this.createEventHook) {
      this.createEventHook(e);
    } else {
      this.mapboxDraw.setFeatureProperty(
        e.features[0].id,
        symbol.key,
        symbol.value.USE_DEFAULT,
      );
    }
  }

  /**
   * 1つの描画が終わった時(その2)
   *
   * これも1つの描画が終わった時に連続描画させるために登録
   * 描画が終わった以外にも発動するので条件を絞っている
   * ref. https://github.com/mapbox/mapbox-gl-draw/blob/main/docs/API.md#drawmodechange
   * @param {MapboxDraw.DrawModeChangeEvent} e
   */
  onDrawModechange(e) {
    // hookが登録されてたら呼び出し
    if (this.modeEventHook) {
      this.modeEventHook(e);
    } else {
      this.onDisable();
    }

    switch (e.mode) {
      case this.mapboxDraw.modes.DRAW_LINE_STRING:
      case this.mapboxDraw.modes.DRAW_POINT:
      case this.mapboxDraw.modes.DRAW_POLYGON:
        this.map.getCanvas().style.cursor = "crosshair";
        break;

      default:
        this.map.getCanvas().style.cursor = "grab";
    }
  }

  /**
   * 作図済みの描画オブジェクトが選択されたとき
   *
   * @param {MapboxDraw.DrawSelectionChangeEvent} e
   */
  onDrawSelectionchange(e) {
    console.log(`selectionchange`);
    console.log(e);
    console.log(this.onDisable);
  }

  /**
   * キー入力されたとき
   *
   * @param {event} e  */
  onKeydown(e) {
    // 描画モード中にEscを押したら初期モードに移動
    if (e.key === "Escape" || e.key === "Esc") {
      this.onDisable();
    }
  }

  /**
   * コントロールが有効になったとき(=作図を開始するとき)の処理
   */
  activate(func) {
    // DrawEventDispatcherなど,他のコントロールに通知
    // 他のコントロールはこれを受け取ったら自分が動かないようにする
    this.map.fire("change-control", {
      controlName: "DrawControl",
      callback: func,
    });
  }

  /**
   * 作図を止める
   */
  onDisable = () => {
    // 描画モード中にEscを押したら描いている途中のものは消す
    if (
      this.mapboxDraw.getMode() === this.mapboxDraw.modes.DRAW_LINE_STRING ||
      this.mapboxDraw.getMode() === this.mapboxDraw.modes.DRAW_POLYGON ||
      this.mapboxDraw.getMode() === this.mapboxDraw.modes.DRAW_POINT ||
      this.mapboxDraw.getMode() === 'DRAW_FREE_HAND_LINE_STRING' ||
      this.mapboxDraw.getMode() === 'DRAW_FREE_HAND_POLYGON' ||
      this.mapboxDraw.getMode() === 'DRAG_CIRCLE' ||
      this.mapboxDraw.getMode() === 'DRAG_RECTANGLE' ||
      this.mapboxDraw.getMode() === 'DRAW_STICKYNOTE' ||
      this.mapboxDraw.getMode() === this.mapboxDraw.modes.TRASH
    ) {
      const lastFeature = this.#getLastUnConfirmedFeature();
      if (lastFeature) {
        this.mapboxDraw.delete(lastFeature.id);
      }

      // 現在の描画モードから, 初期状態に戻す.
      this.mapboxDraw.changeMode(this.mapboxDraw.modes.SIMPLE_SELECT);

      // 作図を止めたら他のレイヤーに対するマウスイベントが動くようにする
      // "end_drawing"は他のレイヤーが受け取ってマウスイベント処理を再開する
      this.map.fire("end_drawing");
    }

    // イベントフックの登録を解除
    // deleteの前にやらないと意図しないフック呼び出しが発生するかも?
    this.createEventHook = null;
    this.modeEventHook = null;
  };

  /**
   * 作図を開始
   * @param {MapboxDraw.DrawMode} mode 作図モード
   * @param {function(MapboxDraw.DrawCreateEvent): void} createEventHook 作図完了時に呼び出すcallback
   * @param {function(MapboxDraw.DrawModeChangeEvent): void} modeEventHook 作図完了してモードが変更される時に呼び出すcallback
   */
  draw = (mode, createEventHook, modeEventHook) => {
    this.activate(() => {
      const lastFeature = this.#getLastUnConfirmedFeature();
      if (lastFeature) {
        this.mapboxDraw.delete(lastFeature.id);
      }

      this.createEventHook = createEventHook;
      this.modeEventHook = modeEventHook;

      this.mapboxDraw.changeMode(mode);
      this.map.getCanvas().style.cursor = "crosshair";
    });
  };

  fileImport = (features) => {
    this.activate(() => {
        this.mapboxDraw.add(features);
    });
  };

  undoRedo = (mode) => {
    this.activate(() => {
      if ((mode === 'undo' && this.historyIndex === 0) ||
          (mode === 'redo' && this.historyIndex === this.historyDraw.length - 1)) {
        return;
      }
      const beforeFeatures = this.historyDraw[this.historyIndex];
      const nextFeatures = mode === 'undo' ? this.historyDraw[this.historyIndex - 1] : this.historyDraw[this.historyIndex + 1];

      if (nextFeatures) {
        beforeFeatures.features.forEach((feature) => {
            this.mapboxDraw.delete(feature.id);
        });

        nextFeatures.features.forEach((feature) => {
            this.mapboxDraw.add(feature);
        });
      }
      this.historyIndex = mode === 'undo' ? this.historyIndex - 1 : this.historyIndex + 1;
    });
  };

  /**
   * polylineの作図を開始
   * @param {function(MapboxDraw.DrawCreateEvent): void} createEventHook 作図完了時に呼び出すcallback
   * @param {function(MapboxDraw.DrawModeChangeEvent): void} modeEventHook 作図完了してモードが変更される時に呼び出すcallback
   */
  drawLine = (createEventHook, modeEventHook) => {
    this.draw(
      this.mapboxDraw.modes.DRAW_LINE_STRING,
      createEventHook,
      modeEventHook,
    );
  };

  /**
   * freehandPolylineの作図を開始
   * @param {function(MapboxDraw.DrawCreateEvent): void} createEventHook 作図完了時に呼び出すcallback
   * @param {function(MapboxDraw.DrawModeChangeEvent): void} modeEventHook 作図完了してモードが変更される時に呼び出すcallback
   */
  drawFreeHandLine = (createEventHook, modeEventHook) => {
    this.draw("DRAW_FREE_HAND_LINE_STRING", createEventHook, modeEventHook);
  };

  /**
   * polygonの作図を開始
   * @param {function(MapboxDraw.DrawCreateEvent): void} createEventHook 作図完了時に呼び出すcallback
   * @param {function(MapboxDraw.DrawModeChangeEvent): void} modeEventHook 作図完了してモードが変更される時に呼び出すcallback
   */
  drawPolygon = (createEventHook, modeEventHook) => {
    this.draw(
      this.mapboxDraw.modes.DRAW_POLYGON,
      createEventHook,
      modeEventHook,
    );
  };

  /**
   * freehandPolygonの作図を開始
   * @param {function(MapboxDraw.DrawCreateEvent): void} createEventHook 作図完了時に呼び出すcallback
   * @param {function(MapboxDraw.DrawModeChangeEvent): void} modeEventHook 作図完了してモードが変更される時に呼び出すcallback
   */
  drawFreeHandPolygon = (createEventHook, modeEventHook) => {
    this.draw("DRAW_FREE_HAND_POLYGON", createEventHook, modeEventHook);
  };

  /**
   * pointの作図を開始
   * @param {function(MapboxDraw.DrawCreateEvent): void} createEventHook 作図完了時に呼び出すcallback
   * @param {function(MapboxDraw.DrawModeChangeEvent): void} modeEventHook 作図完了してモードが変更される時に呼び出すcallback
   */
  drawPoint = (createEventHook, modeEventHook) => {
    this.draw(this.mapboxDraw.modes.DRAW_POINT, createEventHook, modeEventHook);
  };

  /**
   * circleの作図を開始
   * @param {function(MapboxDraw.DrawCreateEvent): void} createEventHook 作図完了時に呼び出すcallback
   * @param {function(MapboxDraw.DrawModeChangeEvent): void} modeEventHook 作図完了してモードが変更される時に呼び出すcallback
   */
  drawCircle = (createEventHook, modeEventHook) => {
    this.draw("DRAG_CIRCLE", createEventHook, modeEventHook);
  };

  /**
   * rectangleの作図を開始
   * @param {function(MapboxDraw.DrawCreateEvent): void} createEventHook 作図完了時に呼び出すcallback
   * @param {function(MapboxDraw.DrawModeChangeEvent): void} modeEventHook 作図完了してモードが変更される時に呼び出すcallback
   */
  drawRectangle = (createEventHook, modeEventHook) => {
    this.draw("DRAG_RECTANGLE", createEventHook, modeEventHook);
  };

  /**
   * stickynoteの作図を開始
   * @param {function(MapboxDraw.DrawCreateEvent): void} createEventHook 作図完了時に呼び出すcallback
   * @param {function(MapboxDraw.DrawModeChangeEvent): void} modeEventHook 作図完了してモードが変更される時に呼び出すcallback
   */
  drawStickyNote = (createEventHook, modeEventHook) => {
    this.draw("DRAW_STICKYNOTE", createEventHook, modeEventHook);
  };

  /**
   * 作図を削除
   * @param {function(MapboxDraw.DrawCreateEvent): void} createEventHook 作図完了時に呼び出すcallback
   * @param {function(MapboxDraw.DrawModeChangeEvent): void} modeEventHook 作図完了してモードが変更される時に呼び出すcallback
   */
  trash = (target) => {
    if (!target) {
      this.mapboxDraw.trash(this.mapboxDraw.getSelected());
    } else {
      this.mapboxDraw.trash(target);
    }
  };

  /**
   * featureのstyleをセット
   * Leaflet.Path.setStyleを模倣したもの
   * @param {GeoJSON.Feature} feature
   * @param {Object} style
   */
  setStyle = (feature, style) => {
    Object.keys(style).forEach((prop) => {
      // アイコンURLが入ってきたらmapに登録する
      if (prop === "_iconUrl") {
        const url = style[prop];
        if (!this.map.hasImage(url)) {
          loadIcon(this.map, url);
        }
      }
      this.mapboxDraw.setFeatureProperty(feature.id, prop, style[prop]);
    });

    // 変更した後に,一旦削除した後に追加し直す
    // 再描画して,指定されたスタイルを反映させるために必要.
    const newFeature = this.mapboxDraw.get(feature.id);
    this.mapboxDraw.delete(feature.id).add(newFeature);
  };

  /**
   * featureを削除
   * @param {GeoJSON.Feature} feature
   */
  delete(feature) {
    this.mapboxDraw.delete(feature.id);
  }

  controlHistory() {
    this.lastFeatures = this.mapboxDraw.getAll();
    this.historyDraw.push(this.lastFeatures);
    this.historyIndex = this.historyDraw.length - 1;
  }
}
