import { Marker } from "maplibre-gl";

/**
 * 画像を使ったMarker
 *
 * map.addLayer() しているどのレイヤーよりも上に表示されることに注意.
 *
 * 中心点で使う.
 */
export default class Icon extends Marker {
  /**
   * constructor
   * @param {maplibregl.MarkerOptions} options
   * @param {String} url 画像のURL
   * @param {Number} size 表示サイズ, 画像はこのサイズに合わせて拡大縮小する
   */
  constructor(options, url, size) {
    const el = document.createElement("div");
    el.className = "marker";
    el.style.backgroundImage = `url(${url})`;
    el.style.width = `${size[0]}px`;
    el.style.height = `${size[1]}px`;
    el.style.backgroundSize = `${size[0]}px`;

    super(el, options);
  }
}
