import * as MapboxDraw from "@mapbox/mapbox-gl-draw";
import {
  DPI,
  Format,
  MaplibreExportControl,
  PageOrientation,
  Size,
} from "@watergis/maplibre-gl-export";
import "@watergis/maplibre-gl-export/dist/maplibre-gl-export.css";
import maplibregl from "maplibre-gl";
import * as PMTiles from "pmtiles";
import DrawFreeHandLineMode from "./draw_mode/freeHandLine";
import DrawFreeHandPolygonMode from "./draw_mode/freeHandPolygon";
import DragCircleMode from "./draw_mode/circle";
import DragRectangleMode from "./draw_mode/rectangle";
import DrawStickyNoteMode from "./draw_mode/stickyNote";
import basemap from "./basemap";
import controlLayerVisibility from "./common/controlLayerVisibility";
import { layerName } from "./common/name";
import layerOpacity from "./consts/layer_opacity";
import DrawControl from "./control/draw_control/draw_control";
import FeatureSelectControl from "./control/feature_select_control/feature_select_control";
import MeasureControl from "./control/measure_control/measure_control";
import defaultConfig from "./default_config";
import Icon from "./layer/icon";
import addLatLngGrid from "./layer/latLngGrid";
import addUTMGrid from "./layer/utmGrid";
import gsiDem from "./protocol/gsi_dem";
import terrainSource from "./source/terrain";
import styles from "./control/draw_control/theme";
import DrawStyle from "./style/draw_style";
import DrawEventDispatcher from "./draw_event_dispatcher";

/**
 * @type {number} 地理院タイルを使った時のmaxZoom. zoom18以降タイルが表示されないのでその直前の値にする.
 */
const GSI_MAX_ZOOM_LEVEL = 17.99;

/**
 * @type {RegExp} 地理院タイルを使った背景地図の名前につけるprefix. basemap.jsに定義
 */
const GSI_LAYER_MATCHER = "/^gsi_/";

/**
 * @type {string} 標高タイルデータのsource名. terrain.jsに定義
 */
const TERRAIN_SOURCE_NAME = "gsidem";

/**
 * @type {string} 作図機能(mapbox-gl-draw)が生成するレイヤー群
 */
const MAPBOX_DRAW_LAYER_ID = "gl-draw";

/**
 * @type {string} 作図機能(mapbox-gl-draw)が生成するsource
 */
const MAPBOX_DRAW_SOURCE_NAME = "mapbox-gl-draw";

/**
 * パッケージ外からアクセスされたくないfunction
 */

/**
 * 背景地図のスタイルを作る. 必要に応じてTokenをスタイルのURLに埋め込む必要がある
 * @param {String} basemapId 背景地図ID
 * @param {String} token ArcGISなど,地図を使うためにAPI Keyなどのトークン
 * @returns mapにセットできるstyle url string or style object
 */
function generateStyle(basemapId, token) {
  const basemapDefinition = basemap[basemapId];
  if (!basemapDefinition) {
    throw new Error(`[${basemapId}]に一致する背景地図がない`);
  }

  if (basemapDefinition.needToken && !token) {
    throw new Error(`[${basemapId}]にはトークンの指定が必要`);
  }

  if (basemapDefinition.needToken) {
    return basemapDefinition.style + token;
  }

  return basemapDefinition.style;
}

/**
 * 地図を作るためのoptionオブジェクトを作る.
 * @param {HTMLElement} entrypoint 地図を配置するElement
 * @param {Object} option ライブラリ利用元から渡されたオプション
 * @returns デフォルト値と渡されたオプションを組み合わせた,地図を作るためのオプションオブジェクト
 */
function generateMapOption(entrypoint, option) {
  const mergedOption = {
    container: entrypoint,
    ...defaultConfig.map,
    locale: defaultConfig.locale,
    ...option,
  };

  mergedOption.style = generateStyle(
    mergedOption.basemapId,
    mergedOption.token,
  );

  if (mergedOption.basemapId.match(GSI_LAYER_MATCHER)) {
    mergedOption.maxZoom = GSI_MAX_ZOOM_LEVEL;
  }

  return mergedOption;
}

/**
 * IdisMapのメインクラス, パッケージ外からアクセス可能
 */
export default class Map {
  /** @type {import("maplibre-gl").Map} map object */
  map;

  /** @type {import("maplibre-gl").MapOptions} map options */
  mapOption;

  /** @type {object.<string, IControl>} mapに登録したコントロール */
  controls = {};

  /** @type {Icon} 地図中心を表すマーカー */
  centerMarker;

  /** @type {import("maplibre-gl").LayerSpecification[]} 背景地図を表示するためのレイヤー群 */
  layers;

  /** @type {string} 背景地図のsource名 */
  basemapSource;

  /** @type{import("./layer/geojson_layer").EventHandler[]} mapに当てたイベントハンドラーのarray */
  eventHandlers = [];

  /**
   * constructor
   * @param {HTMLElement} entrypoint 地図を配置するするElement
   * @param {import("maplibre-gl").MapOptions} option 地図のオプション. ref. https://maplibre.org/maplibre-gl-js-docs/api/map/#map-parameters
   */
  constructor(entrypoint, option) {
    // mapの生成
    this.mapOption = generateMapOption(entrypoint, option);
    this.map = new maplibregl.Map(this.mapOption);

    // コントローラーの作成,配置
    this.#createControl();

    this.controlLayerVisibility = controlLayerVisibility;
    this.addLatLngGrid = addLatLngGrid;
    this.addUTMGrid = addUTMGrid;

    // map eventの動作
    this.map.on("load", this.#onLoad);
    this.map.on("styledata", this.#onStyleData);
  }

  /**
   * mapがloadされた時の動作. callbackに入れるためにarrow functionとして定義.
   */
  #onLoad = () => {
    // 中心点のマーカーを作って表示
    this.centerMarker = new Icon(
      null,
      "/images/center.png",
      [30, 30],
    ).setDraggable(false);
    this.showCenterMark(this.mapOption.showCenterMark || true);

    // 3D表示用のterrainを配置
    Object.keys(terrainSource).forEach((key) => {
      this.map.addSource(key, terrainSource[key]);
    });
    maplibregl.addProtocol(TERRAIN_SOURCE_NAME, gsiDem);

    // PMTiles用のprotocol追加
    const protocol = new PMTiles.Protocol();
    maplibregl.addProtocol("pmtiles", protocol.tile);
  };

  /**
   * styleロード時の動作, 地図の初期表示と背景地図切り替えで発生する.
   *
   * 現在の背景地図に所属するレイヤー/スタイルを保管し,次回の背景地図切り替え時に使う.
   */
  #onStyleData = () => {
    const style = this.map.getStyle();

    // レイヤーは距離/面積計測用と作図のレイヤーを除いて背景地図が提供するレイヤー群だけを保管
    const reLayer = `(idismap-control-|${MAPBOX_DRAW_LAYER_ID})`;
    this.layers = style.layers.filter((l) => !l.id.match(reLayer));

    // sourceも同様に距離/面積計測用と作図のものを除いた,純粋な背景地図用のものだけ保管.
    const reSource = `(idismap-control-|${TERRAIN_SOURCE_NAME}|${MAPBOX_DRAW_SOURCE_NAME})`;
    const sourceKeys = Object.keys(style.sources).filter(
      (k) => !k.match(reSource),
    );
    this.basemapSource = sourceKeys.at(0);
  };

  /**
   * 地図のデフォルトコントローラー配置
   */
  #createControl() {
    this.controls.navigation = new maplibregl.NavigationControl({
      visualizePitch: true,
    });

    this.controls.terrain = new maplibregl.TerrainControl({
      source: TERRAIN_SOURCE_NAME,
      exaggeration: defaultConfig.terrain.exaggeration,
    });

    this.controls.print = new MaplibreExportControl({
      PageSize: Size.A4,
      PageOrientation: PageOrientation.Portrait,
      Format: Format.PNG,
      DPI: DPI[96],
      Crosshair: true,
      PrintableArea: true,
      Local: "en",
    });

    this.controls.mapboxDraw = new MapboxDraw({
      displayControlsDefault: false,
      userProperties: true,
      styles: [styles, DrawStyle].flat(),
      modes: {
        ...MapboxDraw.modes,
        DRAW_FREE_HAND_LINE_STRING: DrawFreeHandLineMode,
        DRAW_FREE_HAND_POLYGON: DrawFreeHandPolygonMode,
        DRAG_CIRCLE: DragCircleMode,
        DRAG_RECTANGLE: DragRectangleMode,
        DRAW_STICKYNOTE: DrawStickyNoteMode
      },
    });

    this.controls.measure = new MeasureControl();
    this.controls.draw = new DrawControl(this.controls.mapboxDraw);
    this.controls.featureSelect = new FeatureSelectControl(
      this.controls.mapboxDraw,
    );

    const placement =
      this.mapOption.controlPlacement || defaultConfig.control.placement;
    this.map.addControl(this.controls.navigation, placement);
    this.map.addControl(this.controls.terrain, placement);
    this.map.addControl(this.controls.print, placement);
    this.map.addControl(this.controls.measure, placement);
    this.map.addControl(this.controls.mapboxDraw, placement);
    this.map.addControl(this.controls.draw, placement);
    this.map.addControl(this.controls.featureSelect, placement);

    const dispatcher = new DrawEventDispatcher(
      this.map,
      this.controls.mapboxDraw,
    );
    dispatcher.addControl(this.controls.measure, "MeasureControl");
    dispatcher.addControl(this.controls.draw, "DrawControl");
    dispatcher.addControl(this.controls.featureSelect, "FeatureSelectControl");
  }

  /**
   * 地図の中心を移動
   * @param {Number} lng 経度
   * @param {Number} lat 緯度
   */
  setCenter(lng, lat) {
    this.map.setCenter([lng, lat]);
  }

  /**
   * 地図本体にイベントを登録
   * @param {String} event イベントタイプ("click"など)
   * @param {Function} func イベント発動時に動くfunction(e) e:event
   */
  addMapEvent(event, func) {
    this.map.on(event, func);
    this.eventHandlers.push({
      type: event,
      func,
    });
  }

  /**
   * 地図に登録したイベントを全て外す
   *
   * GeoJsonLayer.
   */
  off() {
    this.eventHandlers.forEach((eventHandler) => {
      if (eventHandler.id) {
        this.map.off(eventHandler.type, eventHandler.id, eventHandler.func);
      } else {
        this.map.off(eventHandler.type, eventHandler.func);
      }
    });
  }

  /**
   * レイヤーIDをワイルドカードのように検索
   *
   * "layer-id-foo" と言うprefixに対して
   * ["layer-id-foo-symbol", "layer-id-foo-fill"]のようにprefixと一致するレイヤーを検索する
   * @param {String} prefix レイヤー名のprefix
   * @returns {StyleLayer[]} レイヤーのarray
   */
  findLayer(prefix) {
    return this.map
      .getStyle()
      .layers.filter((l) => l.id.startsWith(prefix))
      .map((l) => this.map.getLayer(l.id));
  }

  /**
   * 指定されたレイヤーを削除
   *
   * TODO: 非表示にするだけ?
   * @param {String|Number} id レイヤーID
   */
  removeLayer(id) {
    this.findLayer(layerName(id)).forEach((layer) => {
      this.map.removeLayer(layer.id);
    });
  }

  /**
   * 指定されたIDに紐づくレイヤー群を一番上に移動
   *
   * @param {string|number} id レイヤーID
   */
  toFront(id) {
    // レイヤーIDリストを逆順にして, それを順番に一番上に移動
    // [1, 2, 3] をそのまま順番に一番上に移動させると [3, 2, 1]となってしまい, レイヤー表示順が逆になっちゃう.
    // [3, 2, 1] としてから同じ操作をすると [1, 2, 3]という並びになる.
    const list = this.findLayer(layerName(id))
      .map((layer) => layer.id)
      .reverse();

    // 順番でやるのでfor-loop
    for (let i = 0; i < list.length; i = +1) {
      const layerId = list[i];
      this.map.moveLayer(layerId);
    }
  }

  /**
   * レイヤーの透過度を変更
   * @param {String|Number} id レイヤーID
   * @param {Number} value 透過度 (1:透過しない, 0:透過して見えない)
   */
  setLayerOpacity(id, value) {
    this.findLayer(layerName(id)).forEach((layer) => {
      // opacityを設定する全てのプロパティの値を変える
      // layerのtypeによって指定するものが違うので別出しの一覧を使う.
      layerOpacity[layer.type].forEach((property) => {
        layer.setPaintProperty(property, value);
      });
    });
  }

  /**
   * レイヤーにイベントを登録
   * @param {String} layerId レイヤーID
   * @param {String} event イベントタイプ("click"など)
   * @param {Function} func イベント発動時に動くfunction(e) e:event
   */
  addLayerEvent(layerId, event, func) {
    this.map.on(event, layerId, func);
    this.eventHandlers.push({
      id: layerId,
      type: event,
      func,
    });
  }

  controlLayerVisibility(layerId, visibiity) {
    this.controlLayerVisibility(layerId, visibiity);
  }

  /**
   * 背景地図切り替え
   * @param {String} basemapId 背景地図ID: basemap.jsを参照
   * @param {String} token ArcGISなど,地図を使うためにAPI Keyなどのトークンが必要な場合は指定
   */
  async changeBaseMap(basemapId, token) {
    // 地理院タイルはzoom:18を超えると表示できない
    // 地理院タイルに切り替えた時はmaxZoomを低くして表示されないことを防ぐ
    if (basemapId.match(GSI_LAYER_MATCHER)) {
      this.map.setMaxZoom(GSI_MAX_ZOOM_LEVEL);

      if (this.map.getZoom() > GSI_MAX_ZOOM_LEVEL) {
        this.map.setZoom(GSI_MAX_ZOOM_LEVEL);
      }
    } else {
      this.map.setMaxZoom();
    }

    // 切り替え前に表示しているレイヤーとsourceを新しいstyleに埋め込んで,
    // 切り替え後に再現できるようにする
    const currentStyle = this.map.getStyle();
    const userLayerBeginIndex =
      currentStyle.layers.findIndex(
        (l) => l.id === this.layers[this.layers.length - 1].id,
      ) + 1;
    const userSources = Object.keys(currentStyle.sources).filter(
      (s) => s !== this.basemapSource,
    );

    // styleのjsonをfetchして,切り替え前に乗っていたレイヤー/sourceを追加する
    const style = generateStyle(basemapId, token);
    let newStyle;
    if (typeof style === "string") {
      const response = await fetch(style);
      newStyle = await response.json();
    } else {
      newStyle = style;
    }

    // layerは配列の後ろにconcat
    newStyle.layers = newStyle.layers.concat(
      currentStyle.layers.slice(userLayerBeginIndex),
    );

    // sourceはsourcesオブジェクトのキーを追加
    userSources.forEach((s) => {
      newStyle.sources[s] = currentStyle.sources[s];
    });
    delete newStyle.sources.gsidem.url;
    delete newStyle.sources.gsidem.bounds;

    // 作ったstyle jsonをsetStyleして背景地図切り替え
    this.map.setStyle(newStyle);
  }

  /**
   * レイヤー存在チェック
   * @param {String} layerId
   * @returns レイヤーがある => true, レイヤーがない => false
   */
  hasLayer(layerId) {
    const len = this.findLayer(layerName(layerId)).length;
    return len && len > 0;
  }

  /**
   * 地図を移動
   * @param {LngLat} center 中心点
   * @param {Number} zoom ズームレベル
   */
  setView(center, zoom) {
    this.map.flyTo({
      center,
      zoom,
    });
  }

  /**
   * 地図の中心表示アイコンをつけたり外したりする
   * @param {Boolean} isShow true:表示する, false:表示を消す
   */
  showCenterMark(isShow) {
    if (isShow) {
      this.centerMarker.setLngLat(this.map.getCenter()).addTo(this.map);
      this.map.on("move", this.#onCenterMarkerMove);
    } else {
      this.centerMarker.remove();
      this.map.off("move", this.#onCenterMarkerMove);
    }
  }

  /**
   * 地図移動に合わせて中心点を移動させる.
   *
   * 即時関数で書くとoffできないので,arrow functionとして宣言して使う.
   * @param {Event} e mapイベント内容
   */
  #onCenterMarkerMove = (e) => {
    this.centerMarker.setLngLat(e.target.getCenter());
  };

  /**
   * 地図印刷ダイアログ表示
   *
   * 暫定的な実装.maplibre-gl-exportのコントローラダイアログを開くだけをやる.
   * 将来は専用のダイアログなり地図表示をここでやる.
   */
  showPrintDialog() {
    if (this.controls.print.exportButton.style.display !== "none") {
      this.controls.print.exportButton.click();
    }
  }

  /**
   * FeatureSelectControlを発動
   *
   * 地図がpolygonを描くモードに移行する
   * @param {object[]} features ポリゴン範囲にいるか,を判定するべきfeature群
   */
  featureSelect(features) {
    this.map.fire("feature-select-input", { features });
  }
}
