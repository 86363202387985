/**
 * 標準地図オブジェクト。
 * @module idis/map/IdisMap
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/lang',
    'dojo/topic',
    'dojox/lang/functional/array',
    'leaflet',
    'app/model/LayerStore',
    '../consts/STORAGE_KEY',
    '../consts/QUERY',
    'app/control/LeafletLayerControl',
    'app/config',
    'idis/model/UserInfo',
    '../control/Locator',
    '../util/storage/LocalStorage',
    '../view/draw/HistoricalMap',
    './BaseLayerOptions',
    './BaseLayerUtils',
    './SCALE'
], function(module, array, lang, topic, df, leaflet,
    LayerStore, STORAGE_KEY, QUERY, LayerControl, config, UserInfo, Locator, LocalStorage,
    HistoricalMap, BaseLayerOptions, BaseLayerUtils, SCALE) {
    /**
     * 0から100の整数値またはそれを表す文字列を受け取り、対応する0から1の透過度に変換する。
     * 0のとき完全な不透明となり、100のとき完全な透明となる。
     * @param {number|string} value 0から100の透過度指定
     * @returns {number} 透過度
     * @private
     */
    function _valueToOpacity(value) {
        return (100 - parseInt(value, 10)) / 100;
    }

    // トピック一覧
    var TOPIC = {
        MOVE: module.id + '::move',
        OPEN_POPUP: module.id + '::popup',
        CLICK: module.id + '::click'
    };

    // 中心表示画像のURL
    var CENTER_MARK_URL = '/images/center.png';

    var IdisMap = HistoricalMap.extend({
        /**
         * 最後に取得したURLのクエリー文字列
         * @type {Object}
         */
        _lastQuery: null,

        /**
         * イベント・リスナー一覧
         * @type {Object[]}
         */
        _handles: null,

        /**
         * 表示中のポップアップ一覧
         * @type {Popup[]}
         */
        _popupList: null,

        /**
         * 中心位置を表すアイコン
         * @param {Marker}
         */
        _centerMark: null,

        // コンストラクター
        initialize: function() {
            // superの呼び出し
            HistoricalMap.prototype.initialize.apply(this, arguments);
            // 右下のLeafletロゴを消す
            this.attributionControl.setPrefix('');
            // パラメーターの初期化
            this._handles = [];
            this._popupList = [];


            // IE11の場合、Leafletが "touch対応デバイス" と判断されてしまい
            // パフォーマンス劣化の原因となるため、touch非対応にオプション変更する
            var userAgent = window.navigator.userAgent.toLowerCase();
            if( userAgent.match(/msie/) || userAgent.match(/trident/) ) {
                var ieVersion = userAgent.match(/(msie\s|rv:)([\d\.]+)/)[2];

                if (parseInt(ieVersion, 10) === 11) {
                    leaflet.Browser.touch = false;
                }
            }

            var query = Locator.getQuery();
            // 表示位置が設定されていなければ設定
            if (!this._loaded) {
                // URLに指定がある場合はそちらで初期化する
                var ll = query[QUERY.LATLNG];
                // 無ければ設定ファイルの値を使う
                var config = this.options.config || {};
                // 以上により緯度経度を取得
                var latlng = [config.latitude, config.longitude];
                if(ll){
                    latlng = ll.indexOf(',') > 0 ? ll.split(',') : ll.split('%2C');
                }
                // ズーム・レベルも同様に取得
                var zoom = query[QUERY.ZOOM] || config.zoom || 11;
                // 指定された位置を表示
                this.setView(latlng, zoom);
                this._updateScaleInfo();
            }
            // URLに状態を反映
            this._updateUrl();
            // 縮尺の表示
            new leaflet.Control.Scale({imperial: false}).addTo(this);
            // URL情報を保存しておく
            this._lastQuery = Locator.getQuery();
            // 地図の中心位置を更新
            this.updateCenterMark();
            // LocalStorageの変更を監視
            this._handles.push(LocalStorage.watch(STORAGE_KEY.CENTER_MARK, this.updateCenterMark, this));
            // 背景地図の設定
            this.setBaseLayer();
            // レイヤー管理オブジェクトの設置
            this.layerControl = new LayerControl(this);
            // URLの状態に従いレイヤー一覧を更新
            this._lastLayerQuery = {};
            this.updateLayerList();
            // URLの変更を監視
            this._handles.push(Locator.on('change', lang.hitch(this, this.onChangeState)));
            // 地図の移動を監視
            this.on('move', this._onMapMove, this);
            this.on('moveend', this._onMapMoveEnd, this);
            this.on('zoomend', this._onMapMoveEnd, this);
            this.on('zoomend', this._updateScaleInfo, this);

            // 地図のクリックを監視して, publish
            this.on('click', this._onMapClick, this);

            // dojo/topicを監視
            // 指定座標へ移動
            this._handles.push(topic.subscribe(TOPIC.MOVE, lang.hitch(this, function(payload) {
                var latlng = [payload.lat || payload.latitude, payload.lng || payload.longitude];
                this.setView(latlng, payload.zoom);
            })));
            // 指定されたIDのポップアップを表示
            this._handles.push(topic.subscribe(TOPIC.OPEN_POPUP, lang.hitch(this, function(payload) {
                var layer = this.layerControl.getLayerById(payload.id);
                if (layer) {
                    this.openPopupRecursive(layer);
                }
            })));
        },

        /**
         * 縮尺表示(1/xxx形式の表記)を更新する。
         */
        _updateScaleInfo: function() {
            var scale = SCALE[this.getZoom()];
            this.attributionControl.setPrefix(scale ? ('1/' +  scale) : '');
        },

        /**
         * 地図画面が移動（拡大・縮小を含む）した際に呼ばれる。
         */
        _onMapMove: function() {
            this.updateCenterMark();
        },

        /**
         * 地図画面が移動（拡大・縮小を含む）完了した際に呼ばれる。
         */
        _onMapMoveEnd: function() {
            // 地図の位置情報をURLへ反映
            this._updateUrl();
        },

        /**
         * 現在の地図オブジェクトの状態をURLへ反映する。
         * @private
         */
        _updateUrl: function() {
            var state = {};
            // 緯度経度
            var center = this.getCenter();
            state[QUERY.LATLNG] = center.lat + ',' + center.lng;
            // ズーム・レベル
            state[QUERY.ZOOM] = this.getZoom();
            // URLを更新
            Locator.replaceState(state);
        },

        // デストラクター
        remove: function() {
            // 地図上の全レイヤーを破棄
            this.eachLayer(this.removeLayer, this);
            // Leaflet形式のイベント・リスナーを破棄
            this.off();
            // Dojo形式のイベント・リスナーを破棄
            array.forEach(this._handles, function(handle) {
                handle.remove();
            });
            // 配列要素をクリア
            this._handles = null;
            this._popupList = null;
            this._utmLayerList = null;
            // superの呼び出し
            HistoricalMap.prototype.remove.apply(this, arguments);
        },

        /**
         * レイヤーの表示状態を切り替える。
         * @type {ILayer} layer 表示状態を切り替えるレイヤー
         * @returns {boolean} 追加されたらtrue、除去されたらfalseを返す
         */
        toggleLayer: function(layer) {
            if (this.hasLayer(layer)) {
                this.removeLayer(layer);
                return false;
            } else {
                this.addLayer(layer);
                return true;
            }
        },

        /**
         * 指定したレイヤーと子孫要素のポップアップを全て表示する。
         * @param {ILayer} layer レイヤー
         */
        openPopupRecursive: function(layer) {
            var popup = layer._popup;
            if (this.hasLayer(popup)) {
                // 表示済みなら何もしない
                return;
            }
            if (layer.options.isOutOfZoom) {
                // レイヤーが地図上に非表示なら何もしない
                return;
            }
            if (popup) {
                // レイヤー自体がポップアップを持っているなら表示
                // （レイヤーのopenPopupを使うとMapのclosePopupで既存ポップアップが閉じてしまうので直接追加）
                var latlng = layer._latlng || layer._latlngs[Math.floor(layer._latlngs.length / 2)];
                popup.setLatLng(latlng);
                popup._isOpen = true;
                this._popup = popup;
                popup.addTo(this);
            } else {
                // レイヤーが子要素を持っているなら各子要素について再帰的に実行
                var layers = layer.getLayers();
                if (layers.length) {
                    array.forEach(layers, this.openPopupRecursive, this);
                }
            }
        },

        // ポップアップを閉じる
        closePoup: function(popup) {
            if (!popup && this._popupList) {
                // 引数が無い場合、openPopupRecursiveで追加した全ポップアップも消去する。
                array.forEach(this._popupList, this.closePopup, this);
                this._popupList = [];
            }
            // superの呼び出し
            return HistoricalMap.prototype.closePopup.apply(this, arguments);
        },

        /**
         * 背景地図を設定する。
         * @param {string} [baseLayerId] 背景地図レイヤーの識別子
         */
        setBaseLayer: function(baseLayerId) {
            // 指定が無く、URLから取得出来る場合はそれを使う
            baseLayerId = baseLayerId || Locator.getQuery()[QUERY.BASE_LAYER_ID];
            // 無い場合はサーバの起動モードに応じたデフォルトを指定
            if (!baseLayerId) {
                var baseLayerConfig = config.map.userBaseLayers ? config.map.userBaseLayers[UserInfo.getRunningMode()]: undefined;
                if (baseLayerConfig) {
                    baseLayerId = baseLayerConfig[0];
                }
            }
            // 無い場合は全選択肢の先頭
            BaseLayerUtils.setBaseLayer(this, baseLayerId || BaseLayerOptions[0].id);
        },

        /**
         * Web Storageの状態に従って中心位置の表示を更新する。
         */
        updateCenterMark: function() {
            if (LocalStorage.get(STORAGE_KEY.CENTER_MARK)) {
                // 表示する場合
                // 中心位置を取得
                var center = this.getCenter();
                if (!this._centerMark) {
                    // 初表示ならアイコンを生成
                    this._centerMark = new leaflet.Marker(center, {
                        icon: new leaflet.Icon({iconUrl: CENTER_MARK_URL, iconSize: [30, 30]}),
                        clickable: false,
                        keyboard: false
                    });
                } else {
                    // 表示位置だけ更新
                    this._centerMark.setLatLng(center);
                }
                this.addLayer(this._centerMark);
            } else if (this._centerMark) {
                // 非表示にする場合
                this.removeLayer(this._centerMark);
            }
        },

        /**
         * 経緯度グリッドの表示状態を切り替える。
         * @returns {boolean} 表示した場合はtrue、非表示にした場合はfalse
         */
        toggleLatLngLayer: function() {
            if (!this._latLngLayer) {
                // 初表示ならインスタンスを生成
                this._latLngLayer = new leaflet.LatLngLayer();
            }
            return this.toggleLayer(this._latLngLayer);
        },

        /**
         * UTMグリッドの表示状態を切り替える。
         * @returns {boolean} 表示した場合はtrue、非表示にした場合はfalse
         */
        toggleUtmLayers: function() {
            if (!this._utmLayerList) {
                // 初表示なら各インスタンスを生成
                this._utmLayerList = [
                    new leaflet.ZonelinesLayer(),
                    new leaflet.Line100kLayer(),
                    new leaflet.Line10kLayer(),
                    new leaflet.Line1kLayer(),
                    new leaflet.Line100mLayer()
                ];
            }
            if (this.hasLayer(this._utmLayerList[0])) {
                // 追加済みの場合は全UTM関連レイヤーを除去
                array.forEach(this._utmLayerList, this.removeLayer, this);
                return false;
            } else {
                // 追加済みでない場合は全UTM関連レイヤーを追加
                array.forEach(this._utmLayerList, this.addLayer, this);
                return true;
            }
        },

        /**
         * URLの状態に従ってレイヤー一覧を更新する。
         */
        updateLayerList: function() {
            // URLで指定されたレイヤー識別子と透過度
            var layerQuery = Locator.getLayerQuery();
            // 追加済みレイヤーの削除・更新
            df.forEach(this._lastLayerQuery, function(lastOpacity, layerId) {
                var opacity = layerQuery[layerId];
                if (!opacity) {
                    // レイヤーが削除された場合
                    console.log('removeLayer: ' + layerId);
                    this.layerControl.removeLayerById(layerId);
                    return;
                }
                if (opacity !== lastOpacity) {
                    // 既存レイヤーの透過度が変更された場合
                    console.debug('opacity changed: id=' + layerId + ', before=' + lastOpacity + ', after=' + opacity);
                    // レイヤーが追加済みの場合だけ反応（非同期なので追加前の可能性がある）
                    var layer = this.layerControl.getLayers()[layerId];
                    if (layer) {
                        this.layerControl.setLayerOpacity(layer, _valueToOpacity(opacity));
                    }
                }
            }, this);
            // 新規レイヤーの追加
            df.forEach(layerQuery, function(opacity, layerId) {
                // 前回のURLに入っていなければ追加
                if (!this._lastLayerQuery[layerId]) {
                    LayerStore.get(layerId).then(lang.hitch(this, function(layerInfo) {
                        this.layerControl.addGeneralLayer(layerInfo, _valueToOpacity(opacity));
                    }));
                }
            }, this);
            // 前回の情報として記録
            this._lastLayerQuery = layerQuery;
        },

        /**
         * URLが変わった際に呼ばれる。
         */
        onChangeState: function(/* pop */) {
            // URLの状態を取得
            var query = Locator.getQuery();
            // 画面が変わっていれば何もしない
            if (query[QUERY.PAGE] !== this._lastQuery[QUERY.PAGE]) {
                // URLの記録は更新
                this._lastQuery = query;
                return;
            }
            // 背景地図が変わっていれば更新
            if (query[QUERY.BASE_LAYER_ID] !== this._lastQuery[QUERY.BASE_LAYER_ID]) {
                this.setBaseLayer();
            }
            // レイヤー一覧が変わっていれば更新
            if (query[QUERY.LAYER_LIST] !== this._lastQuery[QUERY.LAYER_LIST]) {
                this.updateLayerList();
            }
            // URLの状態を記憶
            this._lastQuery = query;
        },

        /**
         * map上をclick時した時に発動するfunction
         * @param {*} event
         */
        _onMapClick: function(event) {
            // MapのClickを受けたい別モジュールにpublishで通知
            topic.publish(TOPIC.CLICK, event);
        }
    });

    // イベント一覧を公開
    IdisMap.TOPIC = TOPIC;

    // ユーティリティ関数を公開
    IdisMap.valueToOpacity = _valueToOpacity;

    // モジュールとして返す
    return IdisMap;
});
