export default {
  gsi_pale: {
    label: "淡色",
    style:
      "https://gsi-cyberjapan.github.io/gsivectortile-mapbox-gl-js/pale.json",
    image: "gsi_vector_pale.png",
  },
  gsi_std: {
    label: "標準",
    style:
      "https://gsi-cyberjapan.github.io/gsivectortile-mapbox-gl-js/std.json",
    image: "gis_vector_std.png",
  },
  gsi_blank: {
    label: "白地図",
    style:
      "https://gsi-cyberjapan.github.io/gsivectortile-mapbox-gl-js/blank.json",
    image: "gis_vector_blank.png",
  },
  gsi_std_vertical: {
    label: "標準立体交差",
    style:
      "https://gsi-cyberjapan.github.io/gsivectortile-mapbox-gl-js/std_vertical.json",
    image: "gis_vector_std_vertical.png",
  },
  gsi_raster_seamless_photo: {
    label: "写真",
    style: {
      version: 8,
      sources: {
        "raster-tile": {
          type: "raster",
          tiles: [
            "https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg",
          ],
          tileSize: 256,
          attribution:
            "<a href=https://maps.gsi.go.jp/development/ichiran.html>国土地理院</a>, データソース:Landsat8画像(GSI,TSIC,GEO Grid/AIST),Landsat8画像(courtesy of the U.S. Geological Survey),海底地形(GEBCO), Images on 世界衛星モザイク画像 obtained from <a href=https://lpdaac.usgs.gov/data_access>site</a> maintained by the NASA Land Processes Distributed Active Archive Center (LP DAAC), USGS/Earth Resources Observation and Science (EROS) Center, Sioux Falls, South Dakota, (Year). Source of image data product.",
        },
      },
      layers: [
        {
          id: "gis-raster-seamless-photo",
          type: "raster",
          source: "raster-tile",
          minzoom: 2,
        },
      ],
      glyphs: "https://maps.gsi.go.jp/xyz/noto-jp/{fontstack}/{range}.pbf",
    },
    image: "gsi_ort.png",
  },
  arcgis_osm: {
    label: "OSM",
    style:
      "https://basemaps-api.arcgis.com/arcgis/rest/services/styles/OSM:Standard?type=style&token=",
    image: "arcgis_vector_osm.png",
    needToken: true,
  },
  arcgis_street: {
    label: "道路地図",
    style:
      "https://basemaps-api.arcgis.com/arcgis/rest/services/styles/0fb0ac10931043ba81bef4b2d64d7165?type=style&token=",
    iamge: "arcgis_vector_street.png",
    needToken: true,
  },
  arcgis_world_imagery: {
    label: "衛星画像",
    style:
      "https://basemaps-api.arcgis.com/arcgis/rest/services/styles/ArcGIS:Imagery:Standard?type=style&token=",
    image: "arcgis_world_imagery.png",
    needToken: true,
  },
  osm_bright: {
    label: "標準",
    style: "https://tile.openstreetmap.jp/styles/osm-bright-ja/style.json",
    image: "osm_bright.png",
  },
  osm_maptiler: {
    label: "MapTiler",
    style: "https://tile.openstreetmap.jp/styles/maptiler-basic-ja/style.json",
    iamge: "osm_maptiler.png",
  },
  osm_open_map_tiles: {
    label: "OpenMapTiles",
    style: "https://tile.openstreetmap.jp/styles/openmaptiles/style.json",
    image: "osm_map_tiles.png",
  },
};
