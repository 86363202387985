import { RasterLayer } from "idismap";

/**
 * 土砂災害危険度(1kmメッシュ)など, grib2から生成した画像タイルを表示するレイヤー
 * @module app/observation/map/Grib2MeshLayer
 */
define([
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/date/locale',
    'dojo/promise/all',
    'dojo/topic',
    'idis/consts/QUERY',
    'idis/control/Locator',
    'idis/service/Requester',
    'app/observation/LatestData',
    'app/observation/define/TileUrl',
], function (array, declare, lang, Deferred, locale, all, topic, QUERY, Locator, Requester, LatestData, TileUrl) {

    return declare(null, {
        /** @type {import("idismap").RasterLayer} */
        layer: null,

        /** @type {import("idismap").RasterLayerParameter} */
        layerOption: null,

        /** @type {number} */
        layerId: null,

        /** @type {string} タイルが置いてあるディレクトリ名のsuffix */
        directorySuffix: null,

        /** @type {number} 観測情報の発生時刻間隔 */
        timespan: 10,

        /** @type {object} 今表示している時点でのURL状態, URL更新時の比較用 */
        _lastQuery: {},

        /** @type {string} 観測データの最新時刻を保管 */
        _latestDataTimestamp: null,

        /**
         * コンストラクタ
         * @param {object} layerInfo
         */
        constructor: function (layerInfo) {
            this.statusUrl = layerInfo.layerUrl;
            this.directorySuffix = layerInfo.directorySuffix || '';
            this.timespan = layerInfo.timespan || this.timespan;
            this.layerId = layerInfo.id;
            this.layerOption = this.generateLayerOption(layerInfo);
        },

        /**
         * layerInfoの情報からレイヤーオプションを作る
         *
         * minZoom/maxZoomが入っていたらそれを入れる.
         *
         * 将来 Idismap.RasterLayer.RasterLayerParameterのプロパティが増えて,
         * そのプロパティがlayerInfoの情報を使うときはここに足す.
         * @param {object} layerInfo
         * @returns レイヤーオプションのオブジェクト
         */
        generateLayerOption: function(layerInfo) {
            var result = {};

            // layerInfoに入っているオプションを適用
            ['minZoom', 'maxZoom', 'overlayZoom'].forEach(function(prop) {
                if (layerInfo[prop]) {
                    result[prop] = layerInfo[prop];
                }
            });

            return result;
        },

        /**
         * レイヤー生成
         *
         * @returns {promise} レイヤーが生成されたらresolveされるもの
         */
        createLayer: function () {
            var latestData = new LatestData({ url: this.statusUrl + '/latest.json' });
            var promise = new Deferred();

            // データの最新更新情報を取得
            latestData.load().then(
                lang.hitch(this, function () {
                    var timestamp = latestData.getLatestDateTimestamp();
                    var timestampWithDash = timestamp.replace(
                        /(\d{4}-\d{2}-\d{2}) (\d{2}):(\d{2}):\d{2}/, '$1-$2-$3');

                    // タイルができているかを確認
                    this._latestTileTimestamp(timestampWithDash).then(
                        lang.hitch(this, function (tileTimestamp) {
                            // YYYY-mm-DD HH:MM:ss -> YYYY-mm-DD-HH-MM
                            this._latestDataTimestamp = tileTimestamp;
                            var url = this._createUrl(tileTimestamp);

                            this.layer = new RasterLayer(this.layerId,
                                lang.mixin(this.layerOption, {
                                    tiles: [url]
                                })
                            );

                            this._lastQuery = Locator.getQuery();
                            // URLの変更を監視
                            var handle = Locator.on('change', lang.hitch(this, this.onLocationChanged));

                            this._tellTimeStamp(tileTimestamp);

                            // このレイヤーが削除された時に動かすfunctionを登録
                            this.layer.addRemoveEventHandler(function () {
                                handle.remove();
                                // 時刻表示を消してもらう
                                topic.publish('app/map/TimestampsPanel::disappear');
                            });

                            promise.resolve();
                        }),
                        function (error) {
                            promise.reject(error);
                        }
                    );
                }), function (error) {
                    promise.reject(error);
                }
            );

            return promise;
        },

        /**
         * タイルが存在する最新時刻を探す
         *
         * latestDataから取得した時刻のディレクトリにはまだタイルが生成されていない場合がある.
         * その場合は1つ前の時間を返すようにする.
         * 1つ前の時間については存在チェックは行わず, どんどん遡るような動作はしない
         * @param {string} timestamp
         * @returns {promise}
         */
        _latestTileTimestamp: function (timestamp) {
            // latestDataの時間からタイルを探す
            var directory = this._layerDirectoryName(timestamp);
            // レベル11のタイルが入っているディレクトリを対象にする
            var urlPrefix = this.statusUrl + '/' + directory + this.directorySuffix;
            var dfd = new Deferred();

            // 画像リクエストなので、encodingとhandleAsをこう設定しないと画像があってもerrorに飛んでしまう
            all(
                array.map(this._checkTileUrl, function (url) {
                    return Requester.get(urlPrefix + url, { encoding: null, handleAs: undefined });
                })
            ).then(lang.hitch(this, function () {
                dfd.resolve(timestamp);

            }), lang.hitch(this, function () {
                // timespan(分)だけさかのぼったtimestampを戻す
                dfd.resolve(this.minusTimespan(timestamp));
            }));

            return dfd.promise;
        },

        /**
         * yyyy-mm-dd-hh-mm形式の時刻について,Timespan(分)でしていされた時間を引いて同じ形式の文字列にしたものを戻す
         * @param {string} timestamp
         * @returns
         */
        minusTimespan: function (timestamp) {
            var splitted = timestamp.split('-');
            var date = new Date(splitted[0], splitted[1] - 1, splitted[2], splitted[3], splitted[4]);

            // 時刻がUTCになるから9時間進めつつTimespanを引く
            // date.setHours(date.getHours() + 9);
            date.setMinutes(date.getMinutes() - this.timespan);

            return locale.format(date, {
                datePattern: 'yyyy-MM-dd',
                timePattern: 'HH-mm'
            }).replace(/ /g, '-');
        },

        /**
         * URL変更時の処理
         *
         * 時刻が変わったら観測情報を取得してレイヤーを更新
         * @returns
         */
        onLocationChanged: function () {
            // 現在のクエリー情報を取得
            var query = Locator.getQuery();

            // 緯度経度、レイヤー、ズームレベルが変更された場合はデータの更新はないので何もしない
            // 最新日時が選択されている状態で「最新」ボタンをクリックすると、日時は変わっていないがデータが
            // 更新されている可能性があるので、日付の一致は確認しない
            if (query[QUERY.LATLNG] !== this._lastQuery[QUERY.LATLNG]) {
                this._lastQuery = query;
                return;
            }
            if (query[QUERY.LAYER_LIST] !== this._lastQuery[QUERY.LAYER_LIST]) {
                this._lastQuery = query;
                return;
            }
            if (query[QUERY.ZOOM] !== this._lastQuery[QUERY.ZOOM]) {
                this._lastQuery = query;
                return;
            }

            // 日時や時間モードが指定されてなかった時のデフォルト値
            var datetime = query.datetime ? query.datetime : this._latestDataTimestamp;

            // レイヤーを更新
            this._refresh(datetime);

            // 次の更新に備えてクエリー状態を保存
            this._lastQuery = query;
        },

        /**
         * レイヤー内容を指定された時間の観測情報で更新
         * @param {string} timestamp
         */
        //
        _refresh: function (timestamp) {
            this.layer.changeTileUrl(this._createUrl(timestamp));
            this._tellTimeStamp(timestamp);
        },

        /**
         * timestamp文字列 (yyyy-mm-dd-hh-mm) を esriが出力しているレイヤーディレクトリ (yymmddhhmm) に変換
         * @param {string} timestamp
         * @returns
         */
        _layerDirectoryName: function (timestamp) {
            return timestamp.replace(/(\d{4})-(\d{2})-(\d{2})-(\d{2})-(\d{2})/, '$1$2$3$4$5');
        },

        /**
         * 当該時刻の画像タイルURLを作る
         *
         * @param {string} timestamp
         */
        _createUrl: function (timestamp) {
            var directoryName = this._layerDirectoryName(timestamp);
            // datetimeで表されるタイルURLを取得
            return this.statusUrl + '/' + directoryName + this.directorySuffix + '/{z}/{x}/{y}.png';
        },

        /**
         * 時刻表示パネルに表示する時刻を教える
         */
        _tellTimeStamp: function (timestamp) {
            topic.publish('app/map/TimestampsPanel::show', {
                timestamp: timestamp,
                layerName: this.statusUrl.split('/').slice(-1)[0]
            });
        }
    });
});