import MapboxDraw from "@mapbox/mapbox-gl-draw";

/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */

const DrawRectangleMode = { ...MapboxDraw.modes.draw_polygon };

DrawRectangleMode.onSetup = function onSetup() {
  const polygon = this.newFeature({
    type: MapboxDraw.constants.geojsonTypes.FEATURE,
    properties: {
      isRectangle: true
    },
    geometry: {
      type: MapboxDraw.constants.geojsonTypes.POLYGON,
      coordinates: [[]]
    }
  });

  this.addFeature(polygon);

  this.clearSelectedFeatures();
  MapboxDraw.lib.doubleClickZoom.disable(this);
  this.updateUIClasses({ mouse: MapboxDraw.constants.cursors.ADD });
  this.activateUIButton(MapboxDraw.constants.types.POLYGON);
  this.setActionableState({
    trash: true
  });

  return {
    polygon,
    currentVertexPosition: 0
  };
};

DrawRectangleMode.onTap = function onTap(state, e) {
  // emulate 'move mouse' to update feature coords
  if (state.startPoint) this.onMouseMove(state, e);
  // emulate onClick
  DrawRectangleMode.onClick(state, e);
};

DrawRectangleMode.onClick = function onClick(state, e) {
  // if state.startPoint exist, means its second click
  // change to  simple_select mode
  if (
    state.startPoint &&
    state.startPoint[0] !== e.lngLat.lng &&
    state.startPoint[1] !== e.lngLat.lat
  ) {
    this.updateUIClasses({ mouse: "pointer" });
    MapboxDraw.lib.doubleClickZoom.enable(this);
    state.endPoint = [e.lngLat.lng, e.lngLat.lat];
    return this.changeMode(MapboxDraw.constants.modes.SIMPLE_SELECT, { featuresId: state.polygon.id });
  }
  // on first click, save clicked point coords as starting for  rectangle
  const startPoint = [e.lngLat.lng, e.lngLat.lat];
  state.startPoint = startPoint;
};

DrawRectangleMode.onMouseMove = function onMouseMove(state, e) {
  // if startPoint, update the feature coordinates, using the bounding box concept
  // we are simply using the startingPoint coordinates and the current Mouse Position
  // coordinates to calculate the bounding box on the fly, which will be our rectangle
  if (state.startPoint) {
    state.polygon.updateCoordinate(
      "0.0",
      state.startPoint[0],
      state.startPoint[1]
    ); // minX, minY - the starting point
    state.polygon.updateCoordinate(
      "0.1",
      e.lngLat.lng,
      state.startPoint[1]
    ); // maxX, minY
    state.polygon.updateCoordinate("0.2", e.lngLat.lng, e.lngLat.lat); // maxX, maxY
    state.polygon.updateCoordinate(
      "0.3",
      state.startPoint[0],
      e.lngLat.lat
    ); // minX,maxY
    state.polygon.updateCoordinate(
      "0.4",
      state.startPoint[0],
      state.startPoint[1]
    ); // minX,minY - ending point (equals to starting point)
  }
};

export default DrawRectangleMode;